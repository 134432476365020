import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { PlatformBatteryPatch } from "@ec1/types/PlatformBatteryPatch";
import { useFetcher } from "src/common/fetcher/effects";
import { Battery } from "../../../__generated__/types/Battery";
import { useAuthHeaders } from "../common/authentication/authentication";
import { useProperties } from "../properties/properties";

const URL = "/api/devices/batteries/";

export type BatteryCommand = "charge" | "pause" | "consume" | "smart";

type DeviceEnrollmentData = {
  brand: number;
  username: string;
  password: string;
};

interface IDeviceContext {
  setDevices: React.Dispatch<React.SetStateAction<Battery[] | null>>;
  devices: Battery[] | null;
  totalDevices: number;
  fetchDevice: (deviceId: number) => void;
  fetchAllDevices: () => void;
  fetchDevices: (params?: {
    params: URLSearchParams;
  }) => Promise<{ rows: Battery[]; count: number }>;
  enrollDevice: (
    deviceEnrollmentData: DeviceEnrollmentData
  ) => Promise<number | null>;
  checkEnrollmentStatus: (
    deviceEnrollmentId: number
  ) => Promise<{ status: string | null; deviceIds?: Array<number> }>;
  sendBatteryCommand: (
    batteryId: number,
    command: BatteryCommand
  ) => Promise<{ success: boolean; error: string }>;
  assignCustomerToDevice: (
    deviceId: number,
    customerId: number | null
  ) => Promise<{ success: boolean; result: string | null }>;
  assignPropertyToDevice: (
    deviceId: number,
    propertyId: number | null
  ) => Promise<{ success: boolean; result: string | null }>;
}

const DeviceContext = createContext<IDeviceContext | undefined>(undefined);

interface IDeviceProviderProps {
  children: ReactNode;
}

export const DeviceProvider: React.FC<IDeviceProviderProps> = ({
  children,
}) => {
  const [totalDevices, setTotalDevices] = useState(
    parseInt(localStorage.getItem("@CACHED_NUMBER_OF_DEVICES") ?? "0")
  );
  const headers = useAuthHeaders();

  const [devices, setDevices] = useState<Battery[] | null>(null);
  const [isFetchingAllDevices, setIsFetchingAllDevices] =
    useState<boolean>(false);

  const fetchDevice = useCallback(
    async (deviceId: number) => {
      try {
        const response = await fetch(`/api/devices/batteries/${deviceId}/`, {
          headers,
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const battery = await response.json();

        setDevices((prevDevices) => {
          return prevDevices
            ? [...prevDevices.filter((d) => d.id !== deviceId), battery]
            : [battery];
        });
      } catch (error) {
        console.error(error);
      }
    },
    [headers]
  );

  const fetchAllDevices = useCallback(() => {
    async function fetchAllDevicesAsync() {
      setIsFetchingAllDevices(true);
      const response = await fetch(URL, { headers });
      const data = await response.json();
      if (response.ok) {
        setDevices(
          data.results.map((device: Battery) => ({
            ...device,
            type: "Battery",
          })) as Battery[]
        );
      } else {
        setDevices([]);
      }

      localStorage.setItem("@CACHED_NUMBER_OF_DEVICES", data.count);
      setTotalDevices(data.count);

      setIsFetchingAllDevices(false);
    }
    if (!isFetchingAllDevices) {
      fetchAllDevicesAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headers, totalDevices]); // don't add isFetchingAllDevices

  const fetchDevices = useCallback(
    async ({ params }: { params?: URLSearchParams } = {}): Promise<{
      rows: Battery[];
      count: number;
    }> => {
      // Check if we can use the cached devices
      if (devices && devices.length > 0 && params) {
        const offset = params.get("offset");
        const limit = params.get("limit");
        const paramCount = Array.from(params.keys()).length;

        if (
          offset !== null &&
          limit &&
          !isNaN(parseInt(offset)) &&
          !isNaN(parseInt(limit)) &&
          paramCount === 2
        ) {
          const offsetNum = parseInt(offset);
          const limitNum = parseInt(limit);

          // Use cached devices if offset is within range
          if (offsetNum < devices.length) {
            return {
              rows: devices
                .slice(
                  offsetNum,
                  Math.min(offsetNum + limitNum, devices.length)
                )
                .map((device) => ({ ...device, type: "Battery" })),
              count: totalDevices,
            };
          }
        }
      }

      // If we can't use cached devices, proceed with the API fetch
      try {
        const queryParams = params || new URLSearchParams();
        const keysToRemove: string[] = [];
        let queryParamsStr = "";

        queryParams.forEach((value, key) => {
          if (key === "battery_status") {
            switch (value) {
              case "charging":
                queryParamsStr += "&battery_power__lt=0";
                break;
              case "discharging":
                queryParamsStr += "&battery_power__gt=0";
                break;
              case "idle":
                queryParamsStr += "&battery_power=0";
                break;
              default:
                break;
            }
            keysToRemove.push(key);
          } else if (key === "is_smart_charging") {
            switch (value) {
              case "manual":
                queryParamsStr += "&is_smart_charging=False";
                break;
              case "smart":
                queryParamsStr += "&is_smart_charging=True";
                break;
              default:
                break;
            }
            keysToRemove.push(key);
          } else if (key.startsWith("battery_level")) {
            const operator = key.split("battery_level")[1];
            switch (operator) {
              case "__=":
                queryParamsStr += "&battery_level=" + value;
                keysToRemove.push(key);
                break;
              case "__!=":
                queryParamsStr += "&battery_level__exclude=" + value;
                keysToRemove.push(key);
                break;
              default:
                break;
            }
          }
        });

        keysToRemove.forEach((key) => {
          queryParams.delete(key);
        });

        const response = await fetch(
          `${URL}?${
            decodeURIComponent(queryParams.toString()) + queryParamsStr
          }`,
          {
            headers,
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();

        const updatedRows = data.results.map((device: Battery) => ({
          ...device,
          type: "Battery",
        }));

        return { rows: updatedRows, count: data.count };
      } catch (error) {
        console.error(error);
        return { rows: [], count: 0 };
      }
    },
    [devices, headers, totalDevices]
  );

  const enrollDevice = useCallback(
    async (
      deviceEnrollmentData: DeviceEnrollmentData
    ): Promise<number | null> => {
      try {
        const response = await fetch("/api/devices/enrollment-session/", {
          headers,
          method: "POST",
          body: JSON.stringify(deviceEnrollmentData),
        });
        const data = await response.json();
        return data.id;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    [headers]
  );

  const checkEnrollmentStatus = useCallback(
    async (
      deviceEnrollmentId: number
    ): Promise<{ status: string | null; deviceIds?: Array<number> }> => {
      try {
        const response = await fetch(
          `/api/devices/enrollment-session/${deviceEnrollmentId}/`,
          { headers }
        );
        const data = await response.json();
        if (data.status === "completed") {
          return { status: data.status, deviceIds: data.batteries };
        }
        return { status: data.status };
      } catch (error) {
        console.error(error);
        return { status: null };
      }
    },
    [headers]
  );

  const getErrorMessageHTML = useCallback(async (response: any) => {
    try {
      const text = await response.text();
      const match = text.match(/<pre class="exception_value">(.+?)<\/pre>/);
      return match && match[1] ? match[1] : "An error has occurred.";
    } catch (error) {
      return "An error has occurred.";
    }
  }, []);

  const sendBatteryCommand = useCallback(
    async (
      batteryId: number,
      command: BatteryCommand
    ): Promise<{ success: boolean; error: string }> => {
      try {
        let commandUrl = "";
        switch (command) {
          case "charge":
            commandUrl = "set-charging-mode";
            break;
          case "pause":
            commandUrl = "set-backup-mode";
            break;
          case "consume":
            commandUrl = "set-self-consumption-mode";
            break;
          case "smart":
            commandUrl = "set-smart-charging-mode";
            break;
          default:
            break;
        }
        const response = await fetch(
          `/api/devices/batteries/${batteryId}/${commandUrl}/`,
          {
            headers,
            method: "POST",
          }
        );
        if (response.ok) {
          fetchDevice(batteryId); // Refresh the device
          return { success: true, error: "" };
        }
        throw new Error(await getErrorMessageHTML(response));
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
          return {
            success: false,
            error: error.message || "An error has occurred.",
          };
        } else {
          console.error("An error has occurred.");
          return { success: false, error: "An error has occurred." };
        }
      }
    },
    [headers, getErrorMessageHTML, fetchDevice]
  );

  const assignPropertyToDevice = useCallback(
    async (
      deviceId: number,
      propertyId: number | null
    ): Promise<{ success: boolean; result: string | null }> => {
      try {
        const response = await fetch(
          `/api/devices/batteries/${deviceId}/assign-property/`,
          {
            headers,
            method: "POST",
            body: JSON.stringify({ property_id: propertyId }),
          }
        );

        if (!response.ok) {
          const data = await response.json();
          return { success: false, result: data.assigned_property };
        }

        // Update the device in the context
        setDevices((prevDevices) => {
          if (prevDevices) {
            return prevDevices.map((device) =>
              device.id === deviceId
                ? {
                    ...device,
                    assigned_property:
                      propertyId !== null ? propertyId : undefined,
                  }
                : device
            );
          }
          return prevDevices;
        });

        return { success: true, result: null };
      } catch (error) {
        console.error(error);
        return { success: false, result: error as string };
      }
    },
    [headers]
  );

  const assignCustomerToDevice = useCallback(
    async (
      deviceId: number,
      customerId: number | null
    ): Promise<{ success: boolean; result: string | null }> => {
      try {
        const response = await fetch(
          `/api/devices/batteries/${deviceId}/assign-customer/`,
          {
            headers,
            method: "POST",
            body: JSON.stringify({ customer_id: customerId }),
          }
        );

        if (!response.ok) {
          const data = await response.json();
          return { success: false, result: data.customer };
        }

        /* Reset property assignment */
        return await assignPropertyToDevice(deviceId, null);
      } catch (error) {
        console.error(error);
        return { success: false, result: error as string };
      }
    },
    [headers, assignPropertyToDevice]
  );

  useEffect(() => {
    fetchAllDevices();
  }, [fetchAllDevices, headers]);

  return (
    <DeviceContext.Provider
      value={{
        devices,
        setDevices,
        totalDevices,
        fetchDevice,
        fetchAllDevices,
        fetchDevices,
        enrollDevice,
        checkEnrollmentStatus,
        sendBatteryCommand,
        assignCustomerToDevice,
        assignPropertyToDevice,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};

export function useDevices(): IDeviceContext {
  const context = useContext(DeviceContext);
  if (context === undefined) {
    throw new Error("useDevices must be used within an DeviceProvider");
  }
  return context;
}

export function useDevice(deviceId: number) {
  const { devices, fetchDevice, setDevices } = useDevices();

  const { fetch: fetchPatchDevice } = useFetcher<Battery>(
    `/api/devices/batteries/${deviceId}/`,
    { method: "PATCH" }
  );

  const patchDevice = useCallback(
    async (
      patchData: PlatformBatteryPatch
    ): Promise<{ success: boolean; result: string | null }> => {
      try {
        const serializedData = JSON.stringify(patchData, (_, value) =>
          value === undefined ? null : value
        );
        const device = await fetchPatchDevice(serializedData);
        if (device) {
          setDevices((prev) => {
            if (prev !== null) {
              return [...prev.filter((p) => p.id !== deviceId), device];
            }
            return prev;
          });
          return { success: true, result: null };
        } else {
          return { success: false, result: "Failed to update device" };
        }
      } catch (error) {
        console.error(error);
        return {
          success: false,
          result:
            error instanceof Error
              ? error.message
              : "An unknown error occurred",
        };
      }
    },
    [deviceId, fetchPatchDevice, setDevices]
  );

  const device = useMemo(() => {
    return devices?.find((d) => d.id === deviceId) || null;
  }, [deviceId, devices]);

  useEffect(() => {
    if (deviceId) {
      fetchDevice(deviceId);
    } else {
      console.error(`Invalid deviceId ${deviceId}`);
    }
  }, [fetchDevice, deviceId]);

  return { device, fetchDevice, patchDevice };
}

export function useEntityDevices(customerId?: number, propertyId?: number) {
  const { devices } = useDevices();
  const { properties } = useProperties();

  const entityDevices = useMemo(() => {
    if (devices) {
      if (customerId && properties.length) {
        const customerPropertyIds = properties
          .filter((p) => p.customer === customerId)
          .map((p) => p.id);
        return devices.filter((d) =>
          customerPropertyIds.includes(d.assigned_property)
        );
      }
      if (propertyId) {
        return devices.filter((d) => d.assigned_property === propertyId);
      }
    }
    return null;
  }, [customerId, devices, properties, propertyId]);

  return entityDevices;
}
