import {
  Button,
  ButtonGroup,
  Checkbox,
  Icon,
  InputGroup,
  Intent,
  Menu,
  MenuItem,
  Navbar,
  Popover,
  RangeSlider,
  Slider,
  Spinner,
  Switch,
  Tab,
  Tabs,
} from "@blueprintjs/core";
import { Tick } from "@blueprintjs/icons";
import { ItemRenderer, Select } from "@blueprintjs/select";
import { PropertyTariffMapping } from "@ec1/types/PropertyTariffMapping";
import { Clear, Edit, Save } from "@mui/icons-material";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CiExport, CiImport } from "react-icons/ci";
import { GiPriceTag } from "react-icons/gi";
import { MdHomeFilled } from "react-icons/md";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";
import { GateKeeper } from "src/gk/__generated__/gatekeepers";
import useGatekeeper from "src/gk/useGatekeeper";
import Ec1DateRangePicker from "src/ui/components/Ec1DateRangePicker.react";
import { Ec1Toaster } from "src/ui/components/Ec1Toaster.react";
import { ChartStateV2, getChartGranularityV2 } from "src/ui/utils/chartUtils";
import { PropertyDailyMetrics } from "../../../__generated__/types/PropertyDailyMetrics";
import { useFetch } from "../../../common/fetcher/effects";
import SpinningLogo from "../../../ui/logo/SpinningLogo.react";
import PlatformPropertyAssignWidget from "../properties/PlatformPropertyAssignWidget.react";
import { usePropertyTimezone } from "../properties/properties";
import { getCurrencySymbol } from "../properties/tariffs";
import { useDevice } from "./devices";
import BatteryGraph from "./grid/BatteryGraph.react";
import PlatformBatteryChargingScheduleChart from "./PlatformBatteryChargingScheduleChart.react";
import PlatformBatteryCostChart from "./PlatformBatteryCostChart.react";
import PlatformBatteryMetricsBatteryPowerChart from "./PlatformBatteryMetricsBatteryPowerChart.react";
import PlatformBatteryMetricsComboChart from "./PlatformBatteryMetricsComboChart.react";
import PlatformBatteryMetricsGridPowerChart from "./PlatformBatteryMetricsGridPowerChart.react";
import PlatformBatteryMetricsHomePowerChart from "./PlatformBatteryMetricsHomePowerChart.react";
import PlatformBatteryMetricsSolarPowerChart from "./PlatformBatteryMetricsSolarPowerChart.react";
import PlatformBatteryPriceChart from "./PlatformBatteryPriceChart.react";
import PlatformDeviceDetailViewHeader from "./PlatformDeviceDetailViewHeader.react";
import PlatformSiteDeviceDetailViewMetricsCard from "./PlatformSiteDeviceDetailViewMetricsCard.react";
import useBatteryChargingPlan from "./useBatteryChargingPlan";

type SmartChargingMethod = {
  value: "ALGORITHMIC" | "SCHEDULE";
  title: string;
  subtitle: string;
};

const SMART_CHARGING_METHODS: SmartChargingMethod[] = [
  {
    value: "ALGORITHMIC",
    title: "Algorithmic",
    subtitle: "Schedule is set by algorithm",
  },
  {
    value: "SCHEDULE",
    title: "Schedule",
    subtitle: "Schedule is set by the API",
  },
];

const useStyles = createUseStyles({
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(440px, 1fr))",
    gap: 12,
    background: "#383e47",
    padding: 12,
    paddingTop: 5,
    borderRadius: "0px 0px 4px 4px",
  },
  validationError: {
    color: "#f17575",
    height: 10,
    marginTop: -20,
    marginLeft: 115,
    fontSize: 12,
  },
  validationErrorList: {
    color: "#f17575",
    marginTop: -5,
    marginLeft: 113,
    fontSize: 12,
  },
  slider: {
    "& .bp5-slider-progress": {
      background: "#343b44",
    },
    "& .bp5-slider-progress.bp5-intent-primary": {
      backgroundColor: "#2d72d2",
    },
  },
  sliderNoHandle: {
    extend: "slider",
    "& .bp5-slider-handle": {
      display: "none",
    },
    "& .bp5-slider-progress.bp5-intent-primary": {
      backgroundColor: "#343b44",
    },
  },
  navbar: {
    fontFamily: "Barlow",
    boxShadow: "none !important",
    borderRadius: "4px 4px 0px 0px",
    padding: "0px 12px",
  },
  navbarTitle: {
    paddingLeft: 2,
    fontSize: 22,
    fontFamily: "Barlow",
    fontWeight: "bolder",
  },
  placeholder: {
    height: 360,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgb(37, 42, 49)",
    borderRadius: 4,
    padding: 20,
  },
  placeholderScheduleChart: {
    extend: "placeholder",
    height: 240,
  },
  placeholderComboChart: {
    extend: "placeholder",
    height: 420,
  },
  iconButton: {
    borderRadius: 4,
    "&:active": {
      outline: "none",
      outlineOffset: 0,
    },
    "&:focus": {
      outline: "none",
      outlineOffset: 0,
    },
  },
  smartChargingMethodButton: {
    width: 185,
    outline: "none",
  },
  smartChargingMethodLabel: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontFamily: "Barlow",
    paddingLeft: 2,
  },
  smartChargingMethodMenuItem: {
    outline: "none",
  },
  smartChargingMethodMenuItemLabel: {
    fontFamily: "Barlow",
    flexGrow: "unset !important",
    flexShrink: "unset !important",
  },
});

export default function PlatformSiteDeviceDetailViewPage() {
  const classes = useStyles();

  const { deviceId } = useParams<{ deviceId: string }>();
  const parsedDeviceId = parseInt(deviceId ?? "");

  const { device, fetchDevice, patchDevice } = useDevice(parsedDeviceId);

  const [selectedPropertyId, setselectedPropertyId] = useState(
    device?.assigned_property
  );

  const { batteryChargingPlan, isFetchingBatteryChargingPlan } =
    useBatteryChargingPlan(parsedDeviceId, selectedPropertyId);

  const {
    timezone: propertyTimezone,
    timezoneLabel: propertyTimezoneLabel,
    isTimezoneLoaded,
  } = usePropertyTimezone(device ? selectedPropertyId ?? null : undefined);

  const [smartCharging, setSmartCharging] = useState(false);
  const [isSavingSmartCharging, setIsSavingSmartCharging] = useState(false);

  const [capacityValue, setCapacityValue] = useState("");
  const [capacityUnit, setCapacityUnit] = useState("");
  const [capacityIntent, setCapacityIntent] = useState<Intent>(Intent.NONE);
  const [isEditingCapacity, setIsEditingCapacity] = useState(false);
  const [isSavingCapacity, setIsSavingCapacity] = useState(false);

  const [solarValue, setSolarValue] = useState("");
  const [solarUnit, setSolarUnit] = useState("");
  const [solarIntent, setSolarIntent] = useState<Intent>(Intent.NONE);
  const [isEditingSolar, setIsEditingSolar] = useState(false);
  const [isSavingSolar, setIsSavingSolar] = useState(false);

  const [backupSoc, setBackupSoc] = useState<number | null>(
    device?.battery_backup_soc ?? null
  );
  const [isSavingBackupSoc, setIsSavingBackupSoc] = useState(false);
  const [backupSocToggle, setIsBackupSocToggle] = useState(false);
  const [hasSliderHandle, setHasSliderHandle] = useState(
    device?.battery_backup_soc != null
  );
  const [backupSocKey, setBackupSocKey] = useState(0);

  const [selectedSmartChargingMethod, setSelectedSmartChargingMethod] =
    useState<SmartChargingMethod>(
      SMART_CHARGING_METHODS.find(
        (m) => m.value === device?.smart_charging_method
      ) ?? SMART_CHARGING_METHODS[0]
    );
  const [isSavingSmartChargingMethod, setIsSavingSmartChargingMethod] =
    useState(false);

  const [syncLocation, setSyncLocation] = useState(false);
  const [isSavingSyncLocation, setIsSavingSyncLocation] = useState(false);

  const [syncSolar, setSyncSolar] = useState(false);
  const [isSavingSyncSolar, setIsSavingSyncSolar] = useState(false);

  const [syncCapacity, setSyncCapacity] = useState(false);
  const [isSavingSyncCapacity, setIsSavingSyncCapacity] = useState(false);

  const [minAllowedChargingValue, setMinAllowedChargingValue] = useState("");
  const [minAllowedChargingUnit, setMinAllowedChargingUnit] = useState("");

  const [maxAllowedChargingValue, setMaxAllowedChargingValue] = useState("");
  const [maxAllowedChargingUnit, setMaxAllowedChargingUnit] = useState("");

  const [minAllowedDischargingValue, setMinAllowedDischargingValue] =
    useState("");
  const [minAllowedDischargingUnit, setMinAllowedDischargingUnit] =
    useState("");

  const [maxAllowedDischargingValue, setMaxAllowedDischargingValue] =
    useState("");
  const [maxAllowedDischargingUnit, setMaxAllowedDischargingUnit] =
    useState("");

  const [chargingLimits, setChargingLimits] = useState<[number, number]>([
    0, 0,
  ]);
  const [isSavingChargingLimits, setIsSavingChargingLimits] = useState(false);

  const [dischargingLimits, setDischargingLimits] = useState<[number, number]>([
    0, 0,
  ]);
  const [isSavingDischargingLimits, setIsSavingDischargingLimits] =
    useState(false);

  const [selectedTab, setSelectedTab] = useState<string>("charging_mode");

  const [propertyChangeCounter, setPropertyChangeCounter] = useState(0);

  const [selectedView, setSelectedView] = useState<"COMBINED" | "GRID">("GRID");

  useEffect(() => {
    setPropertyChangeCounter((prev) => prev + 1);
  }, [device?.assigned_property]);

  const timezone = useMemo(() => {
    if (!propertyTimezone && device?.location_timezone) {
      return device.location_timezone;
    }
    return propertyTimezone;
  }, [device?.location_timezone, propertyTimezone]);

  const timezoneLabel = useMemo(() => {
    if (timezone && propertyTimezoneLabel === "Local") {
      // Calculate the timezone offset with GMT prefix
      const offsetHours =
        DateTime.now().setZone(timezone || "system").offset / 60;
      const gmtOffset =
        offsetHours === 0
          ? "GMT"
          : `GMT${offsetHours > 0 ? "+" : ""}${Math.abs(offsetHours)}`;
      return `${timezone} (${gmtOffset})`;
    }
    return propertyTimezoneLabel;
  }, [propertyTimezoneLabel, timezone]);

  useEffect(() => {
    if (isTimezoneLoaded) {
      // Initialize metrics chart state to the current day
      const localNow = DateTime.now().setZone(timezone || "system");
      setMetricsChartState({
        start: localNow.startOf("day"),
        end: localNow.endOf("day"),
        granularity: "30m",
      });

      // Initialize costs chart state to the current month
      setCostChartState({
        start: localNow.startOf("month"),
        end: localNow.endOf("day"),
        granularity: "1d", // Not used
      });
    }
  }, [timezone, isTimezoneLoaded]);

  const isPatchInProgress = useMemo(() => {
    return (
      isSavingSmartCharging ||
      isSavingSmartChargingMethod ||
      isSavingBackupSoc ||
      isSavingChargingLimits ||
      isSavingDischargingLimits ||
      isSavingSyncLocation ||
      isSavingSyncSolar ||
      isSavingSyncCapacity ||
      isSavingCapacity ||
      isSavingSolar
    );
  }, [
    isSavingSmartCharging,
    isSavingSmartChargingMethod,
    isSavingBackupSoc,
    isSavingChargingLimits,
    isSavingDischargingLimits,
    isSavingSyncLocation,
    isSavingSyncSolar,
    isSavingSyncCapacity,
    isSavingCapacity,
    isSavingSolar,
  ]);

  const setActiveTab = (tabId: string) => {
    setSelectedTab(tabId);
  };

  const { data: propertyDailyMetricsDataToday } = useFetch<{
    results: PropertyDailyMetrics[];
  }>(
    selectedPropertyId
      ? `/api/property/daily-metrics/?limit=1&property=${selectedPropertyId}`
      : null,
    { useCache: true }
  );

  const showToast = useCallback(
    (
      message: string,
      intent: Intent,
      buttonText?: string,
      buttonOnClick?: () => void
    ) => {
      Ec1Toaster.show({
        timeout: 5000,
        message: (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: 18,
                fontFamily: "Barlow",
              }}
            >
              <div style={{ marginRight: 15, height: 20 }}>{message}</div>
              {buttonText && (
                <Button
                  intent="primary"
                  text={buttonText}
                  onClick={buttonOnClick}
                  style={{
                    height: 20,
                    padding: "0 5px",
                    minHeight: "unset",
                    minWidth: "unset",
                    fontSize: 12,
                  }}
                />
              )}
            </div>
          </>
        ),
        intent: intent,
      });
    },
    []
  );

  // Auto-refreshing device data every minute
  useEffect(() => {
    const interval = setInterval(() => {
      fetchDevice(parsedDeviceId);
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, [parsedDeviceId, fetchDevice]);

  const setCapacity = useCallback((capacity: number | undefined) => {
    if (capacity) {
      let formattedUnit = "Wh";
      let formattedCapacity = capacity;

      if (capacity >= 1000000) {
        formattedCapacity = +(capacity / 1000000).toFixed(3);
        formattedUnit = "MWh";
      } else if (capacity >= 1000) {
        formattedCapacity = +(capacity / 1000).toFixed(3);
        formattedUnit = "kWh";
      }

      setCapacityValue(formattedCapacity.toString());
      setCapacityUnit(formattedUnit);
    } else {
      setCapacityValue("");
      setCapacityUnit("");
    }
  }, []);

  useEffect(() => {
    if (device?.battery_capacity_in_wh !== undefined) {
      setCapacity(device?.battery_capacity_in_wh);
    }
  }, [device?.battery_capacity_in_wh, setCapacity]);

  const setSolar = useCallback((size: number | undefined) => {
    if (size) {
      let formattedUnit = "kW";
      let formattedCapacity = size;

      if (size >= 1000) {
        formattedCapacity = +(size / 1000).toFixed(3);
        formattedUnit = "MW";
      } else if (size < 1) {
        formattedCapacity = +(size * 1000).toFixed(3);
        formattedUnit = "W";
      }

      setSolarValue(formattedCapacity.toString());
      setSolarUnit(formattedUnit);
    } else {
      setSolarValue("");
      setSolarUnit("");
    }
  }, []);

  const setAllowed = useCallback(
    (
      minCharging: number | undefined,
      maxCharging: number | undefined,
      minDischarging: number | undefined,
      maxDischarging: number | undefined
    ) => {
      if (minCharging) {
        let formattedUnit = "W";
        let formattedCapacity = minCharging;

        if (minCharging >= 1000000) {
          formattedCapacity = +(minCharging / 1000000).toFixed(3);
          formattedUnit = "MW";
        } else if (minCharging >= 1000) {
          formattedCapacity = +(minCharging / 1000).toFixed(3);
          formattedUnit = "kW";
        }

        setMinAllowedChargingValue(formattedCapacity.toString());
        setMinAllowedChargingUnit(formattedUnit);
      } else {
        setMinAllowedChargingValue("");
        setMinAllowedChargingUnit("");
      }

      if (maxCharging) {
        let formattedUnit = "W";
        let formattedCapacity = maxCharging;

        if (maxCharging >= 1000000) {
          formattedCapacity = +(maxCharging / 1000000).toFixed(3);
          formattedUnit = "MW";
        } else if (maxCharging >= 1000) {
          formattedCapacity = +(maxCharging / 1000).toFixed(3);
          formattedUnit = "kW";
        }

        setMaxAllowedChargingValue(formattedCapacity.toString());
        setMaxAllowedChargingUnit(formattedUnit);
      } else {
        setMaxAllowedChargingValue("");
        setMaxAllowedChargingUnit("");
      }

      if (minDischarging) {
        let formattedUnit = "W";
        let formattedCapacity = minDischarging;

        if (minDischarging >= 1000000) {
          formattedCapacity = +(minDischarging / 1000000).toFixed(3);
          formattedUnit = "MW";
        } else if (minDischarging >= 1000) {
          formattedCapacity = +(minDischarging / 1000).toFixed(3);
          formattedUnit = "kW";
        }

        setMinAllowedDischargingValue(formattedCapacity.toString());
        setMinAllowedDischargingUnit(formattedUnit);
      } else {
        setMinAllowedDischargingValue("");
        setMinAllowedDischargingUnit("");
      }

      if (maxDischarging) {
        let formattedUnit = "W";
        let formattedCapacity = maxDischarging;

        if (maxDischarging >= 1000000) {
          formattedCapacity = +(maxDischarging / 1000000).toFixed(3);
          formattedUnit = "MW";
        } else if (maxDischarging >= 1000) {
          formattedCapacity = +(maxDischarging / 1000).toFixed(3);
          formattedUnit = "kW";
        }

        setMaxAllowedDischargingValue(formattedCapacity.toString());
        setMaxAllowedDischargingUnit(formattedUnit);
      } else {
        setMaxAllowedDischargingValue("");
        setMaxAllowedDischargingUnit("");
      }
    },
    []
  );

  const canViewCostComparison = useGatekeeper(
    GateKeeper.CAN_VIEW_COST_COMPARISON
  );

  useEffect(() => {
    if (device?.solar_size_in_kw !== undefined) {
      setSolar(device?.solar_size_in_kw);
    }
  }, [device?.solar_size_in_kw, setSolar]);

  useEffect(() => {
    setBackupSoc(device?.battery_backup_soc ?? null);

    const toggle = device?.battery_backup_soc != null; // Loose inequality needed
    setIsBackupSocToggle(toggle);
    setHasSliderHandle(toggle);
  }, [device?.battery_backup_soc]);

  useEffect(() => {
    if (
      device?.min_allowed_charging_power_in_w !== undefined &&
      device?.max_allowed_charging_power_in_w !== undefined
    ) {
      setChargingLimits([
        device?.min_charging_power_in_w ??
          device.min_allowed_charging_power_in_w,
        device?.max_charging_power_in_w ??
          device.max_allowed_charging_power_in_w,
      ]);
    }
  }, [
    device?.min_allowed_charging_power_in_w,
    device?.max_allowed_charging_power_in_w,
    device?.min_charging_power_in_w,
    device?.max_charging_power_in_w,
  ]);

  useEffect(() => {
    if (
      device?.min_allowed_discharging_power_in_w !== undefined &&
      device?.max_allowed_discharging_power_in_w !== undefined
    ) {
      setDischargingLimits([
        device?.min_discharging_power_in_w ??
          device.min_allowed_discharging_power_in_w,
        device?.max_discharging_power_in_w ??
          device.max_allowed_discharging_power_in_w,
      ]);
    }
  }, [
    device?.min_allowed_discharging_power_in_w,
    device?.max_allowed_discharging_power_in_w,
    device?.min_discharging_power_in_w,
    device?.max_discharging_power_in_w,
  ]);

  useEffect(() => {
    if (device?.sync_location_from_property !== undefined) {
      setSyncLocation(device.sync_location_from_property);
    }
  }, [device?.sync_location_from_property]);

  useEffect(() => {
    if (device?.enable_manual_set_solar_capacity !== undefined) {
      setSyncSolar(!device.enable_manual_set_solar_capacity);
    }
  }, [device?.enable_manual_set_solar_capacity]);

  useEffect(() => {
    if (device?.enable_manual_set_battery_capacity !== undefined) {
      setSyncCapacity(!device.enable_manual_set_battery_capacity);
    }
  }, [device?.enable_manual_set_battery_capacity]);

  useEffect(() => {
    if (device?.is_smart_charging !== undefined) {
      setSmartCharging(device.is_smart_charging);
    }
  }, [device?.is_smart_charging]);

  useEffect(() => {
    if (device?.smart_charging_method !== undefined) {
      setSelectedSmartChargingMethod(
        SMART_CHARGING_METHODS.find(
          (m) => m.value === device?.smart_charging_method
        ) ?? SMART_CHARGING_METHODS[0]
      );
    }
  }, [device?.smart_charging_method]);

  useEffect(() => {
    setAllowed(
      device?.min_allowed_charging_power_in_w,
      device?.max_allowed_charging_power_in_w,
      device?.min_allowed_discharging_power_in_w,
      device?.max_allowed_discharging_power_in_w
    );
  }, [
    device?.max_allowed_charging_power_in_w,
    device?.max_allowed_discharging_power_in_w,
    device?.min_allowed_charging_power_in_w,
    device?.min_allowed_discharging_power_in_w,
    setAllowed,
  ]);

  const deviceFlow = useMemo(() => {
    return <BatteryGraph battery={device} />;
  }, [device]);

  useEffect(() => {
    if (device) {
      setselectedPropertyId(device.assigned_property);
    }
  }, [device]);

  const onBackupSocToggle = useCallback(
    async (e: React.FormEvent<HTMLInputElement>) => {
      const newEnabled = e.currentTarget.checked;

      setIsSavingBackupSoc(true);
      setIsBackupSocToggle(newEnabled);

      // If turning off, update and clear immediately
      if (newEnabled === false) {
        setHasSliderHandle(newEnabled);
        setBackupSoc(null);
      }

      try {
        const response = await patchDevice({
          battery_backup_soc: newEnabled ? 20 : undefined, // undefined to play nice with the type
        });

        if (response.success) {
          // If turning on, wait for success to update
          if (newEnabled) {
            setHasSliderHandle(newEnabled);
            setBackupSoc(20);
          }
          showToast(
            `Battery backup level ${
              newEnabled ? "enabled" : "disabled"
            } successfully!`,
            Intent.SUCCESS
          );
        } else {
          showToast(
            `Failed to ${
              newEnabled ? "enable" : "disable"
            } battery backup level. Please try again.`,
            Intent.DANGER
          );
          // Revert the state if the API call fails
          setIsBackupSocToggle(!newEnabled);
          setHasSliderHandle(!newEnabled);
          setBackupSoc(newEnabled ? null : device?.battery_backup_soc ?? null);
        }
      } catch (error) {
        showToast(
          `An error occurred while ${
            newEnabled ? "enabling" : "disabling"
          } battery backup level. Please try again.`,
          Intent.DANGER
        );
        // Revert the state if the API call fails
        setIsBackupSocToggle(!newEnabled);
        setHasSliderHandle(!newEnabled);
        setBackupSoc(newEnabled ? null : device?.battery_backup_soc ?? null);
      } finally {
        setIsSavingBackupSoc(false);
        setBackupSocKey((prev) => prev + 1); // Force remount
      }
    },
    [patchDevice, showToast, device?.battery_backup_soc]
  );

  const onCapacitySave = useCallback(async () => {
    if (capacityUnit && capacityValue && capacityIntent !== Intent.DANGER) {
      const parsedCapacity = parseFloat(capacityValue);
      if (parsedCapacity <= 0) {
        showToast("Battery capacity must be greater than zero.", Intent.DANGER);
        return;
      }

      setIsSavingCapacity(true);

      const capacityInWh =
        capacityUnit === "MWh"
          ? parsedCapacity * 1000000
          : capacityUnit === "kWh"
          ? parsedCapacity * 1000
          : parsedCapacity;

      const roundedCapacity = Math.round(capacityInWh);

      try {
        const response = await patchDevice({
          battery_capacity_in_wh: roundedCapacity,
        });

        if (response.success) {
          if (response.result) {
            setCapacity(parseFloat(response.result));
          }
          fetchDevice(parsedDeviceId); // Refresh device
          setIsSavingCapacity(false);
          setIsEditingCapacity(false);
          showToast(`Battery capacity changed successfully!`, Intent.SUCCESS);
        } else {
          showToast(
            "Battery capacity change failed. Please try again.",
            Intent.DANGER
          );
          setIsSavingCapacity(false);
        }
      } catch (error) {
        setIsSavingCapacity(false);
        showToast("Battery capacity failed. Please try again.", Intent.DANGER);
      }
    }
  }, [
    capacityUnit,
    capacityValue,
    capacityIntent,
    showToast,
    patchDevice,
    parsedDeviceId,
    fetchDevice,
    setCapacity,
  ]);

  const onCapacityReset = useCallback(() => {
    setCapacity(device?.battery_capacity_in_wh);
    setCapacityIntent(Intent.NONE);
    setIsEditingCapacity(false);
  }, [device?.battery_capacity_in_wh, setCapacity]);

  const validateCapacity = useCallback(
    (newValue: string) => {
      // Regex for matching only valid float or integer values
      const isValidFloat = /^-?\d+(\.\d+)?$/;
      if (isEditingCapacity) {
        if (!newValue || !isValidFloat.test(newValue)) {
          setCapacityIntent(Intent.DANGER);
        } else {
          setCapacityIntent(Intent.NONE);
        }
        setCapacityValue(newValue);
      }
    },
    [isEditingCapacity]
  );

  const onSolarEdit = useCallback(() => {
    onCapacityReset();
    setIsEditingSolar(true);
  }, [onCapacityReset]);

  const onSolarSave = useCallback(async () => {
    if (solarUnit && solarValue && solarIntent !== Intent.DANGER) {
      const parsedSolar = parseFloat(solarValue);
      if (parsedSolar <= 0) {
        showToast("Solar capacity must be greater than zero.", Intent.DANGER);
        return;
      }

      setIsSavingSolar(true);

      const solarInW =
        solarUnit === "MW"
          ? parsedSolar * 1000000
          : solarUnit === "kW"
          ? parsedSolar * 1000
          : parsedSolar;

      const roundedSolar = Math.round(solarInW);

      try {
        const response = await patchDevice({
          solar_size_in_kw: roundedSolar / 1000.0,
        }); // Backend is in kW

        if (response.success) {
          if (response.result) {
            setSolar(parseFloat(response.result));
          }
          fetchDevice(parsedDeviceId); // Refresh device
          setIsSavingSolar(false);
          setIsEditingSolar(false);
          showToast(`Solar capacity changed successfully!`, Intent.SUCCESS);
        } else {
          showToast(
            "Solar capacity change failed. Please try again.",
            Intent.DANGER
          );
          setIsSavingSolar(false);
        }
      } catch (error) {
        setIsSavingSolar(false);
        showToast("Solar capacity failed. Please try again.", Intent.DANGER);
      }
    }
  }, [
    solarUnit,
    solarValue,
    solarIntent,
    showToast,
    patchDevice,
    fetchDevice,
    parsedDeviceId,
    setSolar,
  ]);

  const onSolarReset = useCallback(() => {
    setSolar(device?.solar_size_in_kw ?? 0);
    setSolarIntent(Intent.NONE);
    setIsEditingSolar(false);
  }, [device?.solar_size_in_kw, setSolar]);

  const onCapacityEdit = useCallback(() => {
    onSolarReset();
    setIsEditingCapacity(true);
  }, [onSolarReset]);

  const validateSolar = useCallback(
    (newValue: string) => {
      // Regex for matching only valid float or integer values
      const isValidFloat = /^-?\d+(\.\d+)?$/;
      if (isEditingSolar) {
        if (!newValue || !isValidFloat.test(newValue)) {
          setSolarIntent(Intent.DANGER);
        } else {
          setSolarIntent(Intent.NONE);
        }
        setSolarValue(newValue);
      }
    },
    [isEditingSolar]
  );

  const onBackupSocSave = useCallback(
    async (newBackupSoc: number) => {
      if (backupSoc === undefined) {
        return;
      }

      setIsSavingBackupSoc(true);

      try {
        const response = await patchDevice({
          battery_backup_soc: newBackupSoc,
        });

        if (response.success) {
          if (response.result) {
            setBackupSoc(parseInt(response.result));
          }
          showToast(
            `Backup battery level changed successfully!`,
            Intent.SUCCESS
          );
        } else {
          setBackupSoc(device?.battery_backup_soc ?? null);
          showToast(
            "Backup battery level change failed. Please try again.",
            Intent.DANGER
          );
        }
      } catch (error) {
        setBackupSoc(device?.battery_backup_soc ?? null);
        showToast(
          "Backup battery level change failed. Please try again.",
          Intent.DANGER
        );
      } finally {
        setIsSavingBackupSoc(false);
      }
    },
    [backupSoc, device?.battery_backup_soc, patchDevice, showToast]
  );

  const onChargingLimitsSave = useCallback(
    async (newChargingLimits: [number, number]) => {
      setIsSavingChargingLimits(true);

      try {
        const response = await patchDevice({
          min_charging_power_in_w: newChargingLimits[0],
          max_charging_power_in_w: newChargingLimits[1],
        });

        if (response.success) {
          showToast(
            `Charging power limits changed successfully!`,
            Intent.SUCCESS
          );
        } else {
          setChargingLimits([
            device?.min_charging_power_in_w ??
              device?.min_allowed_charging_power_in_w ??
              0,
            device?.max_charging_power_in_w ??
              device?.max_allowed_charging_power_in_w ??
              0,
          ]);
          showToast(
            "Charging power limits change failed. Please try again.",
            Intent.DANGER
          );
        }
      } catch (error) {
        setChargingLimits([
          device?.min_charging_power_in_w ??
            device?.min_allowed_charging_power_in_w ??
            0,
          device?.max_charging_power_in_w ??
            device?.max_allowed_charging_power_in_w ??
            0,
        ]);
        showToast(
          "Charging power limits change failed. Please try again.",
          Intent.DANGER
        );
      } finally {
        setIsSavingChargingLimits(false);
      }
    },
    [
      device?.max_allowed_charging_power_in_w,
      device?.max_charging_power_in_w,
      device?.min_allowed_charging_power_in_w,
      device?.min_charging_power_in_w,
      patchDevice,
      showToast,
    ]
  );

  const onDischargingLimitsSave = useCallback(
    async (newDischargingLimits: [number, number]) => {
      setIsSavingDischargingLimits(true);

      try {
        const response = await patchDevice({
          min_discharging_power_in_w: newDischargingLimits[0],
          max_discharging_power_in_w: newDischargingLimits[1],
        });

        if (response.success) {
          showToast(
            `Discharging power limits changed successfully!`,
            Intent.SUCCESS
          );
        } else {
          setDischargingLimits([
            device?.min_discharging_power_in_w ??
              device?.min_allowed_discharging_power_in_w ??
              0,
            device?.max_discharging_power_in_w ??
              device?.max_allowed_discharging_power_in_w ??
              0,
          ]);
          showToast(
            "Discharging power limits change failed. Please try again.",
            Intent.DANGER
          );
        }
      } catch (error) {
        setDischargingLimits([
          device?.min_discharging_power_in_w ??
            device?.min_allowed_discharging_power_in_w ??
            0,
          device?.max_discharging_power_in_w ??
            device?.max_allowed_discharging_power_in_w ??
            0,
        ]);
        showToast(
          "Discharging power limits change failed. Please try again.",
          Intent.DANGER
        );
      } finally {
        setIsSavingDischargingLimits(false);
      }
    },
    [
      device?.max_allowed_discharging_power_in_w,
      device?.max_discharging_power_in_w,
      device?.min_allowed_discharging_power_in_w,
      device?.min_discharging_power_in_w,
      patchDevice,
      showToast,
    ]
  );

  const onSyncLocationSave = useCallback(
    async (e: React.FormEvent<HTMLInputElement>) => {
      const newSyncLocation = e.currentTarget.checked;
      setSyncLocation(newSyncLocation);
      setIsSavingSyncLocation(true);

      try {
        const response = await patchDevice({
          sync_location_from_property: newSyncLocation,
        });

        if (response.success) {
          if (response.result) {
            setSyncLocation(Boolean(response.result));
          }
          showToast(
            `Sync location setting changed successfully!`,
            Intent.SUCCESS
          );
        } else {
          setSyncLocation(!newSyncLocation);
          showToast(
            "Sync location setting change failed. Please try again.",
            Intent.DANGER
          );
        }
      } catch (error) {
        setSyncLocation(!newSyncLocation);
        showToast(
          "Sync location setting change failed. Please try again.",
          Intent.DANGER
        );
      } finally {
        setIsSavingSyncLocation(false);
      }
    },
    [patchDevice, showToast]
  );

  const onSyncSolarSave = useCallback(
    async (e: React.FormEvent<HTMLInputElement>) => {
      const newSyncSolar = e.currentTarget.checked;
      setSyncSolar(newSyncSolar);
      setIsSavingSyncSolar(true);

      try {
        const response = await patchDevice({
          enable_manual_set_solar_capacity: !newSyncSolar,
        });

        if (response.success) {
          if (response.result) {
            setSyncSolar(Boolean(response.result));
          }
          showToast(`Sync solar setting changed successfully!`, Intent.SUCCESS);
        } else {
          setSyncSolar(!newSyncSolar);
          showToast(
            "Sync solar setting change failed. Please try again.",
            Intent.DANGER
          );
        }
      } catch (error) {
        setSyncSolar(!newSyncSolar);
        showToast(
          "Sync solar setting change failed. Please try again.",
          Intent.DANGER
        );
      } finally {
        setIsSavingSyncSolar(false);
      }
    },
    [patchDevice, showToast]
  );

  const onSyncCapacitySave = useCallback(
    async (e: React.FormEvent<HTMLInputElement>) => {
      const newSyncCapacity = e.currentTarget.checked;
      setSyncCapacity(newSyncCapacity);
      setIsSavingSyncCapacity(true);

      try {
        const response = await patchDevice({
          enable_manual_set_battery_capacity: !newSyncCapacity,
        });

        if (response.success) {
          if (response.result) {
            setSyncCapacity(Boolean(response.result));
          }
          showToast(
            `Sync capacity setting changed successfully!`,
            Intent.SUCCESS
          );
        } else {
          setSyncCapacity(!newSyncCapacity);
          showToast(
            "Sync capacity setting change failed. Please try again.",
            Intent.DANGER
          );
        }
      } catch (error) {
        setSyncCapacity(!newSyncCapacity);
        showToast(
          "Sync capacity setting change failed. Please try again.",
          Intent.DANGER
        );
      } finally {
        setIsSavingSyncCapacity(false);
      }
    },
    [patchDevice, showToast]
  );

  const tariffMappingFetchUrl = useMemo(() => {
    if (device?.assigned_property) {
      return `/api/tariffs/property-mapping/?property=${device?.assigned_property}`;
    }
    return null;
  }, [device?.assigned_property]);

  const { data: propertyTariffMappingResponse } = useFetch<{
    results: PropertyTariffMapping[];
  }>(tariffMappingFetchUrl, { useCache: true });

  const hasTariff = useMemo(() => {
    if (!device?.assigned_property || !propertyTariffMappingResponse?.results) {
      return false;
    }
    const currentDate = DateTime.now();
    return propertyTariffMappingResponse.results.some((mapping) => {
      const validFrom = DateTime.fromISO(mapping.valid_from);
      const validTo = DateTime.fromISO(mapping.valid_to);
      return (
        currentDate >= validFrom &&
        currentDate <= validTo &&
        mapping.tariff != null
      );
    });
  }, [device?.assigned_property, propertyTariffMappingResponse?.results]);

  const smartChargingDisabled = useMemo(() => {
    return (
      !device?.is_smart_charging &&
      (!hasTariff ||
        !device?.solar_size_in_kw ||
        (!device?.battery_capacity_in_wh && !device?.estimated_capacity_in_wh))
    );
  }, [
    device?.is_smart_charging,
    device?.solar_size_in_kw,
    device?.battery_capacity_in_wh,
    device?.estimated_capacity_in_wh,
    hasTariff,
  ]);

  const smartChargingErrorMessages = useMemo(() => {
    const messages = [];
    if (!hasTariff) {
      messages.push("A tariff must be assigned");
    }
    if (!device?.battery_capacity_in_wh && !device?.estimated_capacity_in_wh) {
      messages.push("Storage capacity must be set");
    }
    if (!device?.solar_size_in_kw) {
      messages.push("Solar panel capacity must be set");
    }
    return messages;
  }, [
    hasTariff,
    device?.battery_capacity_in_wh,
    device?.estimated_capacity_in_wh,
    device?.solar_size_in_kw,
  ]);

  const onSmartChargingSave = useCallback(
    async (e: React.FormEvent<HTMLInputElement>) => {
      const newSmartCharging = e.currentTarget.checked;
      if (smartChargingDisabled) {
        return;
      }

      setIsSavingSmartCharging(true);
      setSmartCharging(newSmartCharging);

      try {
        const response = await patchDevice({
          is_smart_charging: newSmartCharging,
        });

        if (response.success) {
          showToast(
            `Smart charging ${
              newSmartCharging ? "enabled" : "disabled"
            } successfully!`,
            Intent.SUCCESS
          );
        } else {
          showToast(
            `Failed to ${
              newSmartCharging ? "enable" : "disable"
            } smart charging. Please try again.`,
            Intent.DANGER
          );
          setSmartCharging(!newSmartCharging); // Revert the state if the API call fails
        }
      } catch (error) {
        showToast(
          `An error occurred while ${
            newSmartCharging ? "enabling" : "disabling"
          } smart charging. Please try again.`,
          Intent.DANGER
        );
        setSmartCharging(!newSmartCharging); // Revert the state if an error occurs
      } finally {
        setIsSavingSmartCharging(false);
      }
    },
    [smartChargingDisabled, patchDevice, showToast]
  );

  const [metricsChartState, setMetricsChartState] = useState<ChartStateV2>({
    start: undefined,
    end: undefined,
    granularity: "30m",
  });

  const [costChartState, setCostChartState] = useState<ChartStateV2>({
    start: undefined,
    end: undefined,
    granularity: "1d", // Not used
  });

  const renderSmartChargingMethod: ItemRenderer<SmartChargingMethod> = (
    smartChargingMethod: SmartChargingMethod,
    { handleClick, handleFocus }
  ) => {
    return (
      <MenuItem
        key={smartChargingMethod.value}
        onClick={handleClick}
        onFocus={handleFocus}
        className={classes.smartChargingMethodMenuItem}
        textClassName={classes.smartChargingMethodMenuItemLabel}
        icon={
          <div
            style={{
              width: 16,
              height: 16,
              verticalAlign: "unset",
            }}
          >
            {selectedSmartChargingMethod.value ===
              smartChargingMethod.value && <Tick style={{ marginTop: 10 }} />}
          </div>
        }
        text={
          <div>
            <div>{smartChargingMethod.title}</div>
            <div
              className="bp3-text-muted"
              style={{ fontSize: "smaller", color: "#999" }}
            >
              {smartChargingMethod.subtitle}
            </div>
          </div>
        }
      />
    );
  };

  const onSmartChargingMethodChange = useCallback(
    async (smartChargingMethod: SmartChargingMethod) => {
      // No need for an API call if the value is the same
      if (selectedSmartChargingMethod.value === smartChargingMethod.value) {
        return;
      }

      setIsSavingSmartChargingMethod(true);
      setSelectedSmartChargingMethod(smartChargingMethod);

      try {
        const response = await patchDevice({
          smart_charging_method: smartChargingMethod.value,
        });

        if (response.success) {
          showToast(
            "Smart charging method changed successfully!",
            Intent.SUCCESS
          );
        } else {
          showToast(
            "Failed to change smart charging method. Please try again.",
            Intent.DANGER
          );
          // Revert the state if the API call fails
          setSelectedSmartChargingMethod(
            SMART_CHARGING_METHODS.find(
              (m) => m.value === device?.smart_charging_method
            ) ?? SMART_CHARGING_METHODS[0]
          );
        }
      } catch (error) {
        showToast(
          "Failed to change smart charging method. Please try again.",
          Intent.DANGER
        );
        // Revert the state if the API call fails
        setSelectedSmartChargingMethod(
          SMART_CHARGING_METHODS.find(
            (m) => m.value === device?.smart_charging_method
          ) ?? SMART_CHARGING_METHODS[0]
        );
      } finally {
        setIsSavingSmartChargingMethod(false);
      }
    },
    [
      selectedSmartChargingMethod.value,
      patchDevice,
      showToast,
      device?.smart_charging_method,
    ]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        width: "100%",
        padding: "24px",
        marginBottom: "64px",
      }}
    >
      {device !== null ? (
        <div style={{ flex: 1, fontFamily: "Barlow" }}>
          <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
            <div
              style={{
                borderRadius: 6,
                padding: "24px",
                paddingBottom: 18,
                fontFamily: "Barlow",
                width: "100%",
                backgroundColor: "#252A31",
                fontFeatureSettings: "Barlow",
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(500px, 1fr))",
                gap: "24px",
              }}
            >
              <PlatformDeviceDetailViewHeader
                device={device}
                setActiveTab={setActiveTab}
              />

              <div
                style={{
                  display: "grid",
                  gridTemplateRows: "auto 1fr",
                  height: "100%",
                  marginBottom: "12px",
                }}
              >
                {selectedPropertyId && device.is_smart_charging && (
                  <div
                    style={{
                      position: "relative",
                      height: 240,
                      minWidth: 540,
                      maxWidth: 800,
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: 240,
                      }}
                    >
                      <PlatformBatteryChargingScheduleChart
                        height={240}
                        batteryId={parsedDeviceId}
                        propertyId={selectedPropertyId}
                        key={`schedule-${propertyChangeCounter}`}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {deviceId && (
            <div>
              <div
                id="device-tabs-container"
                style={{
                  marginTop: "24px",
                  borderColor: "#5F6B7C",
                  borderWidth: "1px",
                  borderStyle: "dashed",
                  padding: "8px 12px",
                  height: "320px",
                  borderRadius: "8px",
                  flex: 1,
                }}
              >
                <Tabs
                  selectedTabId={selectedTab}
                  onChange={(newTabId) => setSelectedTab(String(newTabId))}
                >
                  <Tab
                    id="charging_mode"
                    title="Charging Settings"
                    tagContent={
                      smartChargingErrorMessages.length === 0
                        ? undefined
                        : smartChargingErrorMessages.length
                    }
                    tagProps={{
                      intent: "danger",
                      style: {
                        fontSize: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      },
                    }}
                    panel={
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "12px",
                            flexDirection: "column",
                            width: 540,
                            height: 142,
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  opacity:
                                    smartChargingDisabled ||
                                    isPatchInProgress ||
                                    isEditingCapacity ||
                                    isEditingSolar
                                      ? 0.5
                                      : 1,
                                  cursor: smartChargingDisabled
                                    ? "not-allowed"
                                    : "default",
                                  marginRight: 10,
                                  width: 100,
                                }}
                              >
                                Smart Charging:
                              </div>
                              <Switch
                                disabled={
                                  isPatchInProgress ||
                                  smartChargingDisabled ||
                                  isEditingCapacity ||
                                  isEditingSolar
                                }
                                onChange={onSmartChargingSave}
                                checked={smartCharging}
                                innerLabel="disabled"
                                innerLabelChecked={"enabled"}
                                large
                              />
                              {isSavingSmartCharging && (
                                <div>
                                  <Spinner size={18} style={{ marginTop: 1 }} />
                                </div>
                              )}
                            </div>
                            {smartChargingDisabled && (
                              <div className={classes.validationErrorList}>
                                The following are mandatory to enable smart
                                charging:
                                <ul
                                  style={{
                                    margin: "8px 0 0 20px",
                                    padding: 0,
                                  }}
                                >
                                  {smartChargingErrorMessages.map(
                                    (message, index) => (
                                      <li key={index}>{message}</li>
                                    )
                                  )}
                                </ul>
                              </div>
                            )}
                            {smartCharging && (
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: 3,
                                }}
                              >
                                <div
                                  style={{
                                    opacity:
                                      smartChargingDisabled ||
                                      isPatchInProgress ||
                                      isEditingCapacity ||
                                      isEditingSolar
                                        ? 0.5
                                        : 1,
                                    cursor: smartChargingDisabled
                                      ? "not-allowed"
                                      : "default",
                                    marginRight: 10,
                                    width: 150,
                                    alignContent: "center",
                                  }}
                                >
                                  Smart Charging Method:
                                </div>
                                <Select<SmartChargingMethod>
                                  items={SMART_CHARGING_METHODS}
                                  itemRenderer={renderSmartChargingMethod}
                                  activeItem={selectedSmartChargingMethod}
                                  onItemSelect={onSmartChargingMethodChange}
                                  disabled={
                                    isPatchInProgress ||
                                    smartChargingDisabled ||
                                    isEditingCapacity ||
                                    isEditingSolar
                                  }
                                  filterable={false}
                                  popoverProps={{ matchTargetWidth: true }}
                                  menuProps={{
                                    style: {
                                      padding: 0,
                                      maxHeight: 200,
                                      overflow: "auto",
                                    },
                                  }}
                                >
                                  <Button
                                    disabled={
                                      isPatchInProgress ||
                                      smartChargingDisabled ||
                                      isEditingCapacity ||
                                      isEditingSolar
                                    }
                                    loading={isSavingSmartChargingMethod}
                                    rightIcon="caret-down"
                                    alignText="left"
                                    className={
                                      classes.smartChargingMethodButton
                                    }
                                    textClassName={
                                      classes.smartChargingMethodLabel
                                    }
                                    text={selectedSmartChargingMethod.title}
                                  />
                                </Select>
                              </div>
                            )}
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: 22,
                            }}
                          >
                            <Checkbox
                              checked={backupSocToggle}
                              disabled={
                                isPatchInProgress ||
                                isEditingCapacity ||
                                isEditingSolar
                              }
                              onChange={onBackupSocToggle}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <div
                                style={{
                                  marginBottom: "0.5em",
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <span
                                  style={{
                                    width: 140,
                                    opacity:
                                      !backupSocToggle ||
                                      isPatchInProgress ||
                                      isEditingCapacity ||
                                      isEditingSolar
                                        ? 0.5
                                        : 1,
                                    marginLeft: -3,
                                  }}
                                >
                                  Battery Backup Level:
                                </span>
                                <div
                                  style={{
                                    marginLeft: 8,
                                    marginTop: 2,
                                    display: "flex",
                                    flexDirection: "row",
                                    width: 300,
                                  }}
                                >
                                  <Slider
                                    key={backupSocKey}
                                    min={0}
                                    max={100}
                                    stepSize={1}
                                    labelStepSize={20}
                                    disabled={
                                      !backupSocToggle ||
                                      isPatchInProgress ||
                                      isEditingCapacity ||
                                      isEditingSolar
                                    }
                                    onChange={setBackupSoc}
                                    onRelease={onBackupSocSave}
                                    labelRenderer={(val: number) => {
                                      return `${val}%`;
                                    }}
                                    value={backupSoc || 0}
                                    className={
                                      hasSliderHandle
                                        ? classes.slider
                                        : classes.sliderNoHandle
                                    }
                                  />
                                </div>
                                {isSavingBackupSoc && (
                                  <div>
                                    <Spinner
                                      size={18}
                                      style={{ marginLeft: 20 }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          {device.min_allowed_charging_power_in_w &&
                            device.max_allowed_charging_power_in_w && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div
                                    style={{
                                      marginBottom: "0.5em",
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <span
                                      style={{
                                        width: 140,
                                      }}
                                    >
                                      Charging Power:
                                    </span>
                                    <div
                                      style={{
                                        marginLeft: 8,
                                        marginTop: 2,
                                        display: "flex",
                                        flexDirection: "row",
                                        width: 300,
                                      }}
                                    >
                                      <RangeSlider
                                        min={
                                          device.min_allowed_charging_power_in_w
                                        }
                                        max={
                                          device.max_allowed_charging_power_in_w
                                        }
                                        stepSize={
                                          device.max_allowed_charging_power_in_w -
                                            device.min_allowed_charging_power_in_w >
                                          0
                                            ? (device.max_allowed_charging_power_in_w -
                                                device.min_allowed_charging_power_in_w) /
                                              100.0
                                            : 1
                                        }
                                        labelStepSize={
                                          device.max_allowed_charging_power_in_w -
                                            device.min_allowed_charging_power_in_w >
                                          0
                                            ? (device.max_allowed_charging_power_in_w -
                                                device.min_allowed_charging_power_in_w) /
                                              5.0
                                            : 1
                                        }
                                        disabled={
                                          isPatchInProgress ||
                                          isEditingCapacity ||
                                          isEditingSolar
                                        }
                                        onChange={setChargingLimits}
                                        onRelease={onChargingLimitsSave}
                                        labelRenderer={(val: number, opts) => {
                                          return (
                                            <div
                                              style={{
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              {(val / 1000.0).toFixed(
                                                opts?.isHandleTooltip ? 2 : 1
                                              )}{" "}
                                              kW
                                            </div>
                                          );
                                        }}
                                        value={chargingLimits}
                                        className={classes.slider}
                                      />
                                    </div>
                                    {isSavingChargingLimits && (
                                      <div>
                                        <Spinner
                                          size={18}
                                          style={{ marginLeft: 20 }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}

                          {device.min_allowed_discharging_power_in_w &&
                            device.max_allowed_discharging_power_in_w && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div
                                    style={{
                                      marginBottom: "0.5em",
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <span
                                      style={{
                                        width: 140,
                                      }}
                                    >
                                      Discharging Power:
                                    </span>
                                    <div
                                      style={{
                                        marginLeft: 8,
                                        marginTop: 2,
                                        display: "flex",
                                        flexDirection: "row",
                                        width: 300,
                                      }}
                                    >
                                      <RangeSlider
                                        min={
                                          device.min_allowed_discharging_power_in_w
                                        }
                                        max={
                                          device.max_allowed_discharging_power_in_w
                                        }
                                        stepSize={
                                          device.max_allowed_discharging_power_in_w -
                                            device.min_allowed_discharging_power_in_w >
                                          0
                                            ? (device.max_allowed_discharging_power_in_w -
                                                device.min_allowed_discharging_power_in_w) /
                                              100.0
                                            : 1
                                        }
                                        labelStepSize={
                                          device.max_allowed_discharging_power_in_w -
                                            device.min_allowed_discharging_power_in_w >
                                          0
                                            ? (device.max_allowed_discharging_power_in_w -
                                                device.min_allowed_discharging_power_in_w) /
                                              5.0
                                            : 1
                                        }
                                        disabled={
                                          isPatchInProgress ||
                                          isEditingCapacity ||
                                          isEditingSolar
                                        }
                                        onChange={setDischargingLimits}
                                        onRelease={onDischargingLimitsSave}
                                        labelRenderer={(val: number, opts) => {
                                          return (
                                            <div
                                              style={{
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              {(val / 1000.0).toFixed(
                                                opts?.isHandleTooltip ? 2 : 1
                                              )}{" "}
                                              kW
                                            </div>
                                          );
                                        }}
                                        value={dischargingLimits}
                                        className={classes.slider}
                                      />
                                    </div>
                                    {isSavingDischargingLimits && (
                                      <div>
                                        <Spinner
                                          size={18}
                                          style={{ marginLeft: 20 }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>
                        {!isFetchingBatteryChargingPlan && (
                          <div style={{ minWidth: 320, minHeight: 142 }}>
                            <fieldset
                              style={{
                                borderColor: "#5F6B7C",
                                borderWidth: "1px",
                                borderStyle: "dashed",
                                padding: 12,
                                borderRadius: 8,
                                marginLeft: -2,
                                display: "flex",
                                gap: "12px",
                                flexDirection: "column",
                                paddingBottom: 6,
                                marginBottom: 12,
                                marginTop: -8,
                                paddingRight: 15,
                              }}
                            >
                              <legend
                                style={{ padding: "0px 5px", fontWeight: 500 }}
                              >
                                Schedule
                              </legend>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginBottom: 2,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginBottom: "0.5em",
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <span
                                        style={{
                                          width: 85,
                                          textAlign: "left",
                                          fontWeight: 500,
                                        }}
                                      >
                                        Charge Slots:
                                      </span>
                                      <span
                                        style={{
                                          marginLeft: 8,
                                        }}
                                      >
                                        {batteryChargingPlan?.chargeSlotsStr
                                          .map((t) => t.substring(0, 5))
                                          .join(", ")}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginBottom: 2,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginBottom: "0.5em",
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <span
                                        style={{
                                          width: 85,
                                          textAlign: "left",
                                          fontWeight: 500,
                                        }}
                                      >
                                        Export Slots:
                                      </span>
                                      <span
                                        style={{
                                          marginLeft: 8,
                                        }}
                                      >
                                        {batteryChargingPlan?.exportSlotsStr
                                          .map((t) => t.substring(0, 5))
                                          .join(", ")}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginBottom: 2,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginBottom: "0.5em",
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <span
                                        style={{
                                          width: 85,
                                          textAlign: "left",
                                          fontWeight: 500,
                                        }}
                                      >
                                        Slot Duration:
                                      </span>
                                      <span
                                        style={{
                                          marginLeft: 8,
                                        }}
                                      >
                                        {`${batteryChargingPlan?.slotDuration} mins`}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                        )}
                      </div>
                    }
                  />
                  <Tab
                    id="entity_connections"
                    tagContent={device?.assigned_property ? undefined : "1"}
                    tagProps={{
                      intent: "danger",
                      style: {
                        fontSize: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      },
                    }}
                    title={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Entity Connections
                      </div>
                    }
                    panel={
                      <div style={{ flex: 1 }}>
                        <div
                          style={{
                            display: "flex",
                            gap: "12px",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "4px",
                            }}
                          >
                            <div
                              style={{
                                marginBottom: "6px",
                                opacity: isPatchInProgress ? 0.5 : 1,
                              }}
                            >
                              <strong>Property</strong>
                            </div>
                            <div
                              style={{
                                opacity:
                                  syncLocation || isPatchInProgress ? 0.5 : 1,
                                cursor:
                                  syncLocation || isPatchInProgress
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                            >
                              <div
                                style={{
                                  pointerEvents:
                                    syncLocation || isPatchInProgress
                                      ? "none"
                                      : "auto",
                                }}
                              >
                                <PlatformPropertyAssignWidget
                                  propertyId={selectedPropertyId}
                                  deviceId={device?.id}
                                />
                              </div>
                            </div>
                          </div>
                          <div style={{ flex: 1 }}>
                            <div
                              style={{
                                display: "flex",
                                marginTop: -6,
                              }}
                            >
                              <Switch
                                checked={syncLocation}
                                label="Sync location from property"
                                disabled={
                                  isPatchInProgress ||
                                  isEditingCapacity ||
                                  isEditingSolar
                                }
                                onChange={onSyncLocationSave}
                              />
                              {isSavingSyncLocation && (
                                <div>
                                  <Spinner
                                    size={18}
                                    style={{ marginLeft: 10 }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  />
                  <Tab
                    id="device_properties"
                    tagContent={
                      !device?.solar_size_in_kw &&
                      !device?.battery_capacity_in_wh
                        ? "2"
                        : !device?.solar_size_in_kw ||
                          !device?.battery_capacity_in_wh
                        ? "1"
                        : undefined
                    }
                    tagProps={{
                      intent: "danger",
                      style: {
                        fontSize: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      },
                    }}
                    title={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Device Properties
                      </div>
                    }
                    panel={
                      <div style={{ flex: 1 }}>
                        <div
                          style={{
                            display: "flex",
                            gap: "12px",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <div
                                style={{
                                  marginBottom: "0.5em",
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <span
                                  style={{
                                    width: 110,
                                    opacity: isPatchInProgress ? 0.5 : 1,
                                  }}
                                >
                                  Solar Capacity:
                                </span>
                                <div
                                  style={{
                                    marginLeft: 5,
                                    marginTop: -2,
                                    display: "flex",
                                    flexDirection: "row",
                                    width: 140,
                                  }}
                                >
                                  <InputGroup
                                    small
                                    readOnly={!isEditingSolar}
                                    disabled={isPatchInProgress}
                                    value={solarValue?.toString()}
                                    onChange={(e) =>
                                      validateSolar(e.target.value)
                                    }
                                    intent={
                                      !device?.solar_size_in_kw
                                        ? Intent.DANGER
                                        : solarIntent
                                    }
                                    style={{
                                      width: 95,
                                      fontFamily: "Barlow",
                                      fontSize: 13,
                                      paddingBottom: 1,
                                      color: isPatchInProgress
                                        ? "rgba(95, 107, 124, 0.6)"
                                        : "white",
                                      cursor: isEditingSolar
                                        ? isPatchInProgress
                                          ? "not-allowed"
                                          : "text"
                                        : "default",
                                      textAlign: "center",
                                      backgroundColor: "rgb(61 67 74)",
                                      borderRadius: 3,
                                    }}
                                    rightElement={
                                      <Popover
                                        disabled={!isEditingSolar}
                                        content={
                                          <Menu style={{ minWidth: 50 }}>
                                            <MenuItem
                                              text="W"
                                              style={{
                                                fontFamily: "Barlow",
                                                fontSize: 12,
                                                padding: 0,
                                                paddingLeft: 4,
                                              }}
                                              onClick={() => setSolarUnit("W")}
                                            />
                                            <MenuItem
                                              text="kW"
                                              style={{
                                                fontFamily: "Barlow",
                                                fontSize: 12,
                                                padding: 4,
                                                paddingLeft: 4,
                                              }}
                                              onClick={() => setSolarUnit("kW")}
                                            />
                                            <MenuItem
                                              text="MW"
                                              style={{
                                                fontFamily: "Barlow",
                                                fontSize: 12,
                                                padding: 0,
                                                paddingLeft: 4,
                                              }}
                                              onClick={() => setSolarUnit("MW")}
                                            />
                                          </Menu>
                                        }
                                        placement="bottom-end"
                                      >
                                        <Button
                                          minimal
                                          disabled={isPatchInProgress}
                                          rightIcon={
                                            <Icon
                                              icon="caret-down"
                                              style={{
                                                color:
                                                  isEditingSolar &&
                                                  !isPatchInProgress
                                                    ? "white"
                                                    : "transparent",
                                              }}
                                            />
                                          }
                                          style={{
                                            fontFamily: "Barlow",
                                            fontSize: 12,
                                            width: 50,
                                            color: "white",
                                            pointerEvents: isEditingSolar
                                              ? "unset"
                                              : "none",
                                          }}
                                        >
                                          {solarUnit}
                                        </Button>
                                      </Popover>
                                    }
                                  />
                                  {isSavingSolar ? (
                                    <Spinner
                                      size={18}
                                      style={{ marginLeft: 5 }}
                                    />
                                  ) : isEditingSolar ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <div
                                        style={{
                                          height: 20,
                                          padding: 5,
                                          paddingRight: 0,
                                        }}
                                      >
                                        <Clear
                                          style={{
                                            color: "#aaa",
                                            cursor: "pointer",
                                            fontSize: 14,
                                          }}
                                          onMouseOver={({ currentTarget }) =>
                                            (currentTarget.style.color = "#eee")
                                          }
                                          onMouseOut={({ currentTarget }) =>
                                            (currentTarget.style.color = "#aaa")
                                          }
                                          onClick={onSolarReset}
                                        />
                                      </div>
                                      <div style={{ height: 20, padding: 5 }}>
                                        <Save
                                          style={{
                                            color: "#aaa",
                                            cursor: "pointer",
                                            fontSize: 14,
                                          }}
                                          onMouseOver={({ currentTarget }) =>
                                            (currentTarget.style.color = "#eee")
                                          }
                                          onMouseOut={({ currentTarget }) =>
                                            (currentTarget.style.color = "#aaa")
                                          }
                                          onClick={onSolarSave}
                                        />
                                      </div>
                                    </div>
                                  ) : syncSolar ? null : (
                                    <div
                                      style={{
                                        height: 20,
                                        padding: 5,
                                        cursor: isPatchInProgress
                                          ? "not-allowed"
                                          : "default",
                                      }}
                                    >
                                      <Edit
                                        style={{
                                          color: "#aaa",
                                          cursor: "pointer",
                                          fontSize: 14,
                                          opacity: isPatchInProgress ? 0.5 : 1,
                                          pointerEvents: isPatchInProgress
                                            ? "none"
                                            : "auto",
                                        }}
                                        onMouseOver={({ currentTarget }) =>
                                          (currentTarget.style.color = "#eee")
                                        }
                                        onMouseOut={({ currentTarget }) =>
                                          (currentTarget.style.color = "#aaa")
                                        }
                                        onClick={onSolarEdit}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                marginTop: 1,
                              }}
                            >
                              <Switch
                                checked={syncSolar}
                                label="Sync from device"
                                disabled={
                                  isPatchInProgress ||
                                  isEditingSolar ||
                                  isEditingCapacity
                                }
                                onChange={onSyncSolarSave}
                              />
                              {isSavingSyncSolar && (
                                <div>
                                  <Spinner
                                    size={18}
                                    style={{ marginLeft: 6, marginTop: 1 }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <div className={classes.validationError}>
                            {!device.solar_size_in_kw
                              ? "Please set the solar capacity"
                              : ""}
                          </div>

                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <div
                                style={{
                                  marginBottom: "0.5em",
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <span
                                  style={{
                                    width: 110,
                                    opacity: isPatchInProgress ? 0.5 : 1,
                                  }}
                                >
                                  Storage Capacity:
                                </span>
                                <div
                                  style={{
                                    marginLeft: 5,
                                    marginTop: -2,
                                    display: "flex",
                                    flexDirection: "row",
                                    width: 140,
                                  }}
                                >
                                  <InputGroup
                                    small
                                    readOnly={!isEditingCapacity}
                                    disabled={isPatchInProgress}
                                    value={capacityValue?.toString()}
                                    onChange={(e) =>
                                      validateCapacity(e.target.value)
                                    }
                                    intent={
                                      !device?.battery_capacity_in_wh
                                        ? Intent.DANGER
                                        : capacityIntent
                                    }
                                    style={{
                                      width: 95,
                                      fontFamily: "Barlow",
                                      fontSize: 13,
                                      paddingBottom: 1,
                                      color: isPatchInProgress
                                        ? "rgba(95, 107, 124, 0.6)"
                                        : "white",
                                      cursor: isEditingCapacity
                                        ? isPatchInProgress
                                          ? "not-allowed"
                                          : "text"
                                        : "default",
                                      textAlign: "center",
                                      backgroundColor: "rgb(61 67 74)",
                                      borderRadius: 3,
                                    }}
                                    rightElement={
                                      <Popover
                                        disabled={!isEditingCapacity}
                                        content={
                                          <Menu style={{ minWidth: 50 }}>
                                            <MenuItem
                                              text="Wh"
                                              style={{
                                                fontFamily: "Barlow",
                                                fontSize: 12,
                                                padding: 0,
                                                paddingLeft: 4,
                                              }}
                                              onClick={() =>
                                                setCapacityUnit("Wh")
                                              }
                                            />
                                            <MenuItem
                                              text="kWh"
                                              style={{
                                                fontFamily: "Barlow",
                                                fontSize: 12,
                                                padding: 4,
                                                paddingLeft: 4,
                                              }}
                                              onClick={() =>
                                                setCapacityUnit("kWh")
                                              }
                                            />
                                            <MenuItem
                                              text="MWh"
                                              style={{
                                                fontFamily: "Barlow",
                                                fontSize: 12,
                                                padding: 0,
                                                paddingLeft: 4,
                                              }}
                                              onClick={() =>
                                                setCapacityUnit("MWh")
                                              }
                                            />
                                          </Menu>
                                        }
                                        placement="bottom-end"
                                      >
                                        <Button
                                          minimal
                                          disabled={isPatchInProgress}
                                          rightIcon={
                                            <Icon
                                              icon="caret-down"
                                              style={{
                                                color:
                                                  isEditingCapacity &&
                                                  !isPatchInProgress
                                                    ? "white"
                                                    : "transparent",
                                              }}
                                            />
                                          }
                                          style={{
                                            fontFamily: "Barlow",
                                            fontSize: 12,
                                            width: 50,
                                            color: "white",
                                            pointerEvents: isEditingCapacity
                                              ? "unset"
                                              : "none",
                                          }}
                                        >
                                          {capacityUnit}
                                        </Button>
                                      </Popover>
                                    }
                                  />
                                  {isSavingCapacity ? (
                                    <Spinner
                                      size={18}
                                      style={{ marginLeft: 5 }}
                                    />
                                  ) : isEditingCapacity ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <div
                                        style={{
                                          height: 20,
                                          padding: 5,
                                          paddingRight: 0,
                                        }}
                                      >
                                        <Clear
                                          style={{
                                            color: "#aaa",
                                            cursor: "pointer",
                                            fontSize: 14,
                                          }}
                                          onMouseOver={({ currentTarget }) =>
                                            (currentTarget.style.color = "#eee")
                                          }
                                          onMouseOut={({ currentTarget }) =>
                                            (currentTarget.style.color = "#aaa")
                                          }
                                          onClick={onCapacityReset}
                                        />
                                      </div>
                                      <div style={{ height: 20, padding: 5 }}>
                                        <Save
                                          style={{
                                            color: "#aaa",
                                            cursor: "pointer",
                                            fontSize: 14,
                                          }}
                                          onMouseOver={({ currentTarget }) =>
                                            (currentTarget.style.color = "#eee")
                                          }
                                          onMouseOut={({ currentTarget }) =>
                                            (currentTarget.style.color = "#aaa")
                                          }
                                          onClick={onCapacitySave}
                                        />
                                      </div>
                                    </div>
                                  ) : syncCapacity ? null : (
                                    <div
                                      style={{
                                        height: 20,
                                        padding: 5,
                                        cursor: isPatchInProgress
                                          ? "not-allowed"
                                          : "default",
                                      }}
                                    >
                                      <Edit
                                        style={{
                                          color: "#aaa",
                                          cursor: "pointer",
                                          fontSize: 14,
                                          opacity: isPatchInProgress ? 0.5 : 1,
                                          pointerEvents: isPatchInProgress
                                            ? "none"
                                            : "auto",
                                        }}
                                        onMouseOver={({ currentTarget }) =>
                                          (currentTarget.style.color = "#eee")
                                        }
                                        onMouseOut={({ currentTarget }) =>
                                          (currentTarget.style.color = "#aaa")
                                        }
                                        onClick={onCapacityEdit}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                marginTop: 1,
                              }}
                            >
                              <Switch
                                checked={syncCapacity}
                                label="Sync from device"
                                disabled={
                                  isPatchInProgress ||
                                  isEditingCapacity ||
                                  isEditingSolar
                                }
                                onChange={onSyncCapacitySave}
                              />
                              {isSavingSyncCapacity && (
                                <div>
                                  <Spinner
                                    size={18}
                                    style={{ marginLeft: 6, marginTop: 1 }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <div className={classes.validationError}>
                            {!device.battery_capacity_in_wh
                              ? "Please set the storage capacity"
                              : ""}
                          </div>
                        </div>
                      </div>
                    }
                  />
                  <Tab
                    id="station_features"
                    title="Charging Features"
                    panel={
                      <div style={{ flex: 1 }}>
                        <div
                          style={{
                            display: "flex",
                            gap: "12px",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <div
                              style={{
                                opacity: 0.5,
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  cursor: "default",
                                  width: 115,
                                }}
                              >
                                Can force import:
                              </div>
                              <Checkbox
                                readOnly
                                checked={device.can_force_import}
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                            </div>
                          </div>

                          <div
                            style={{ flex: 1, marginTop: -6, marginBottom: 6 }}
                          >
                            <div
                              style={{
                                opacity: 0.5,
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  cursor: "default",
                                  width: 115,
                                }}
                              >
                                Can force export:
                              </div>
                              <Checkbox
                                readOnly
                                checked={device.can_force_export}
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                            </div>
                          </div>

                          {device?.min_allowed_charging_power_in_w &&
                            device?.max_allowed_charging_power_in_w && (
                              <fieldset
                                style={{
                                  borderColor: "#5F6B7C",
                                  borderWidth: "1px",
                                  borderStyle: "dashed",
                                  padding: 12,
                                  borderRadius: 8,
                                  marginLeft: -2,
                                  display: "flex",
                                  gap: "12px",
                                  flexDirection: "column",
                                  paddingBottom: 6,
                                  marginBottom: 12,
                                  marginTop: -8,
                                  width: 340,
                                  paddingRight: 0,
                                }}
                              >
                                <legend style={{ padding: "0px 5px" }}>
                                  Charging Power
                                </legend>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginBottom: 2,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginBottom: "0.5em",
                                          display: "flex",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <span
                                          style={{
                                            width: 80,
                                            textAlign: "right",
                                          }}
                                        >
                                          Min allowed:
                                        </span>
                                        <span
                                          style={{
                                            marginLeft: 8,
                                            fontWeight: 500,
                                            width: 80,
                                          }}
                                        >
                                          {`${minAllowedChargingValue?.toString()} ${minAllowedChargingUnit}`}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginBottom: "0.7em",
                                          display: "flex",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <span
                                          style={{
                                            width: 80,
                                            textAlign: "right",
                                          }}
                                        >
                                          Max allowed:
                                        </span>
                                        <span
                                          style={{
                                            marginLeft: 8,
                                            fontWeight: 500,
                                            width: 80,
                                          }}
                                        >
                                          {`${maxAllowedChargingValue?.toString()} ${maxAllowedChargingUnit}`}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </fieldset>
                            )}

                          {device?.min_allowed_discharging_power_in_w &&
                            device?.max_allowed_discharging_power_in_w && (
                              <fieldset
                                style={{
                                  borderColor: "#5F6B7C",
                                  borderWidth: "1px",
                                  borderStyle: "dashed",
                                  padding: 12,
                                  borderRadius: 8,
                                  marginLeft: -2,
                                  display: "flex",
                                  gap: "12px",
                                  flexDirection: "column",
                                  paddingBottom: 6,
                                  marginBottom: 12,
                                  marginTop: -8,
                                  width: 340,
                                  paddingRight: 0,
                                }}
                              >
                                <legend style={{ padding: "0px 5px" }}>
                                  Discharging Power
                                </legend>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginBottom: 2,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginBottom: "0.7em",
                                          display: "flex",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <span
                                          style={{
                                            width: 80,
                                            textAlign: "right",
                                          }}
                                        >
                                          Min allowed:
                                        </span>
                                        <span
                                          style={{
                                            marginLeft: 8,
                                            fontWeight: 500,
                                            width: 80,
                                          }}
                                        >
                                          {`${minAllowedDischargingValue?.toString()} ${minAllowedDischargingUnit}`}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginBottom: "0.5em",
                                          display: "flex",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <span
                                          style={{
                                            width: 80,
                                            textAlign: "right",
                                          }}
                                        >
                                          Max allowed:
                                        </span>
                                        <span
                                          style={{
                                            marginLeft: 8,
                                            fontWeight: 500,
                                            width: 80,
                                          }}
                                        >
                                          {`${maxAllowedDischargingValue?.toString()} ${maxAllowedDischargingUnit}`}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </fieldset>
                            )}
                        </div>
                      </div>
                    }
                  />
                </Tabs>
              </div>
              <div style={{ display: "flex" }}></div>
              <div
                style={{
                  marginTop: "24px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginRight: "-6px",
                    marginLeft: "-6px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <PlatformSiteDeviceDetailViewMetricsCard
                      icon={<MdHomeFilled size={40} />}
                      metric={`${(
                        propertyDailyMetricsDataToday?.results?.[0]
                          ?.consumed_energy_in_kwh ?? 0
                      ).toFixed(1)} kWh`}
                      label={"Home Consumption"}
                    />
                    <PlatformSiteDeviceDetailViewMetricsCard
                      icon={<CiImport size={40} />}
                      metric={`${(
                        propertyDailyMetricsDataToday?.results?.[0]
                          ?.imported_grid_energy_in_kwh ?? 0
                      ).toFixed(1)} kWh`}
                      label={"Grid Energy Import"}
                    />
                    <PlatformSiteDeviceDetailViewMetricsCard
                      icon={<CiExport size={40} />}
                      metric={`${(
                        propertyDailyMetricsDataToday?.results?.[0]
                          ?.exported_grid_energy_in_kwh ?? 0
                      ).toFixed(1)} kWh`}
                      label={"Grid Energy Export"}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <PlatformSiteDeviceDetailViewMetricsCard
                      icon={<GiPriceTag size={40} color="#BDADFF" />}
                      metric={`${
                        (propertyDailyMetricsDataToday?.results?.[0]
                          ?.avg_price_per_kwh ?? 0) < 0
                          ? "-"
                          : ""
                      }${getCurrencySymbol(
                        propertyDailyMetricsDataToday?.results?.[0]
                          ?.tariff_currency ?? "EUR"
                      )} ${(
                        propertyDailyMetricsDataToday?.results?.[0]
                          ?.avg_price_per_kwh ?? 0
                      ).toFixed(2)} `}
                      label={"Average import price / kWh"}
                    />
                    <PlatformSiteDeviceDetailViewMetricsCard
                      icon={<GiPriceTag size={40} color="#D69FD6" />}
                      metric={`${
                        (propertyDailyMetricsDataToday?.results?.[0]
                          ?.avg_export_price_per_kwh ?? 0) < 0
                          ? "-"
                          : ""
                      }${getCurrencySymbol(
                        propertyDailyMetricsDataToday?.results?.[0]
                          ?.tariff_currency ?? "EUR"
                      )} ${Math.abs(
                        propertyDailyMetricsDataToday?.results?.[0]
                          ?.avg_export_price_per_kwh ?? 0
                      ).toFixed(2)} `}
                      label={"Average export price / kWh"}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: "24px",
                }}
              >
                <Navbar className={classes.navbar}>
                  <Navbar.Group align="left">
                    <div className={classes.navbarTitle}>Usage</div>
                  </Navbar.Group>
                  <Navbar.Group align="right">
                    {isTimezoneLoaded && (
                      <Ec1DateRangePicker
                        minDate={DateTime.fromISO(
                          device?.utc_time_metrics_start ?? "",
                          {
                            zone: "utc",
                          }
                        )
                          .setZone(timezone)
                          .startOf("day")}
                        maxDate={DateTime.now()
                          .setZone(timezone)
                          .plus({ days: 1 })
                          .endOf("day")}
                        fromDate={metricsChartState.start}
                        toDate={metricsChartState.end}
                        timezone={timezone}
                        onChange={(from: DateTime, to: DateTime) => {
                          setMetricsChartState({
                            start: from,
                            end: to,
                            granularity: getChartGranularityV2(from, to),
                          });
                        }}
                        timePicker={true}
                      />
                    )}
                    <ButtonGroup style={{ marginLeft: 15 }}>
                      <Button
                        outlined
                        icon={<Icon icon="grid-view" color="white" size={14} />}
                        active={selectedView === "GRID"}
                        title="Grid View"
                        className={classes.iconButton}
                        onClick={() => {
                          setSelectedView("GRID");
                        }}
                      />
                      <Button
                        outlined
                        icon={
                          <Icon
                            icon="timeline-line-chart"
                            color="white"
                            size={15} // 1 greater than grids
                          />
                        }
                        active={selectedView === "COMBINED"}
                        title="Combined View"
                        className={classes.iconButton}
                        onClick={() => {
                          setSelectedView("COMBINED");
                        }}
                      />
                    </ButtonGroup>
                  </Navbar.Group>
                </Navbar>

                <div className={classes.gridContainer}>
                  {selectedView === "GRID" ? (
                    <>
                      {isTimezoneLoaded ? (
                        <PlatformBatteryMetricsSolarPowerChart
                          batteryId={parsedDeviceId}
                          height={360}
                          key={`solar-${propertyChangeCounter}`}
                          chartState={metricsChartState}
                          setChartState={setMetricsChartState}
                          timezone={timezone}
                          timezoneLabel={timezoneLabel}
                        />
                      ) : (
                        <div className={classes.placeholder}>
                          <Spinner />
                        </div>
                      )}
                      {isTimezoneLoaded ? (
                        <PlatformBatteryMetricsBatteryPowerChart
                          batteryId={parsedDeviceId}
                          height={360}
                          key={`battery-${propertyChangeCounter}`}
                          chartState={metricsChartState}
                          setChartState={setMetricsChartState}
                          timezone={timezone}
                          timezoneLabel={timezoneLabel}
                        />
                      ) : (
                        <div className={classes.placeholder}>
                          <Spinner />
                        </div>
                      )}
                      {isTimezoneLoaded ? (
                        <PlatformBatteryMetricsGridPowerChart
                          batteryId={parsedDeviceId}
                          height={360}
                          key={`grid-${propertyChangeCounter}`}
                          chartState={metricsChartState}
                          setChartState={setMetricsChartState}
                          timezone={timezone}
                          timezoneLabel={timezoneLabel}
                        />
                      ) : (
                        <div className={classes.placeholder}>
                          <Spinner />
                        </div>
                      )}
                      {isTimezoneLoaded ? (
                        <PlatformBatteryMetricsHomePowerChart
                          batteryId={parsedDeviceId}
                          height={360}
                          key={`home-${propertyChangeCounter}`}
                          chartState={metricsChartState}
                          setChartState={setMetricsChartState}
                          timezone={timezone}
                          timezoneLabel={timezoneLabel}
                        />
                      ) : (
                        <div className={classes.placeholder}>
                          <Spinner />
                        </div>
                      )}
                      {device?.assigned_property &&
                        (isTimezoneLoaded ? (
                          <PlatformBatteryPriceChart
                            propertyId={device.assigned_property}
                            height={360}
                            key={`prices-${propertyChangeCounter}`}
                            chartState={metricsChartState}
                            setChartState={setMetricsChartState}
                            timezone={timezone}
                            timezoneLabel={timezoneLabel}
                          />
                        ) : (
                          <div className={classes.placeholder}>
                            <Spinner />
                          </div>
                        ))}
                    </>
                  ) : isTimezoneLoaded ? (
                    <PlatformBatteryMetricsComboChart
                      batteryId={parsedDeviceId}
                      propertyId={device?.assigned_property}
                      height={420}
                      key={`combo-${propertyChangeCounter}`}
                      chartState={metricsChartState}
                      setChartState={setMetricsChartState}
                      timezone={timezone}
                      timezoneLabel={timezoneLabel}
                      currency={getCurrencySymbol(
                        propertyDailyMetricsDataToday?.results?.[0]
                          ?.tariff_currency ?? "EUR"
                      )}
                    />
                  ) : (
                    <div className={classes.placeholderComboChart}>
                      <Spinner />
                    </div>
                  )}
                </div>
              </div>

              {/* Needed to fix pixelation */}
              <div style={{ height: 358, paddingTop: 1, paddingBottom: 1 }}>
                {deviceFlow}
              </div>

              {canViewCostComparison && selectedPropertyId && (
                <div
                  style={{
                    marginTop: "24px",
                    maxWidth: 600,
                  }}
                >
                  <Navbar className={classes.navbar}>
                    <Navbar.Group align="left">
                      <div className={classes.navbarTitle}>
                        Revenue Breakdown
                      </div>
                    </Navbar.Group>
                    <Navbar.Group align="right">
                      {isTimezoneLoaded && (
                        <Ec1DateRangePicker
                          minDate={DateTime.fromISO(
                            device?.utc_time_metrics_start ?? "",
                            {
                              zone: "utc",
                            }
                          )
                            .setZone(timezone)
                            .startOf("day")}
                          maxDate={DateTime.now()
                            .setZone(timezone)
                            .endOf("day")}
                          fromDate={costChartState.start}
                          toDate={costChartState.end}
                          timezone={timezone}
                          onChange={(from: DateTime, to: DateTime) => {
                            setCostChartState({
                              start: from,
                              end: to,
                              granularity: "1d", // Not used
                            });
                          }}
                        />
                      )}
                    </Navbar.Group>
                  </Navbar>

                  <div className={classes.gridContainer}>
                    {isTimezoneLoaded ? (
                      <PlatformBatteryCostChart
                        propertyId={device?.assigned_property}
                        height={360}
                        key={`cost-${propertyChangeCounter}`}
                        chartState={costChartState}
                        timezone={timezone}
                      />
                    ) : (
                      <div className={classes.placeholder}>
                        <Spinner />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            margin: "auto 0",
          }}
        >
          <SpinningLogo isLoadingSpinner={true} height="50px" />
        </div>
      )}
    </div>
  );
}
