import { Checkbox } from "@blueprintjs/core";
import { AssetEnrollmentCandidate } from "@ec1/types/AssetEnrollmentCandidate";
import { MdOutlineSolarPower } from "react-icons/md";
import { PiBatteryChargingFill } from "react-icons/pi";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  container: {
    marginTop: "8px",
    padding: "8px",
    backgroundColor: "#404854",
    display: "flex",
    alignItems: "center",
    transition: "background-color 0.1s ease, opacity 0.1s ease",
    cursor: "pointer",
    "&:hover:not($disabledContainer)": {
      backgroundColor: "#4F5B69", // Lighter shade for hover
    },
  },
  selectedContainer: {
    backgroundColor: "#2F3841", // Darker shade for selected state
    "&:hover:not($disabledContainer)": {
      backgroundColor: "#374350", // Slightly lighter shade for hover on selected state
    },
  },
  disabledContainer: {
    opacity: 0.5,
    cursor: "not-allowed",
    pointerEvents: "none",
  },
  checkbox: {
    margin: 0,
  },
  iconContainer: {
    marginRight: "8px",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",

    flex: 1,
  },
  locationText: {
    fontSize: "12px",
  },
});

interface PlatformVendorAccountDeviceEnrolmentCandidateItemProps {
  candidate: AssetEnrollmentCandidate;
  selected?: boolean;
  disabled?: boolean;
  onSelect: () => void;
  onUnselect: () => void;
}

export default function PlatformVendorAccountDeviceEnrolmentCandidateItem({
  selected = false,
  disabled = false,

  candidate,

  onSelect,
  onUnselect,
}: PlatformVendorAccountDeviceEnrolmentCandidateItemProps) {
  const classes = useStyles();

  const handleClick = (e: React.MouseEvent) => {
    if (disabled) {
      return;
    }
    if (selected) {
      onUnselect();
    } else {
      onSelect();
    }
    e.stopPropagation();
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      return;
    }
    if (e.target.checked) {
      onUnselect();
    } else {
      onSelect();
    }
  };

  return (
    <div
      className={`${classes.container} ${
        selected ? classes.selectedContainer : ""
      } ${disabled ? classes.disabledContainer : ""}`}
      onClick={handleClick}
    >
      <div>
        <Checkbox
          disabled={disabled}
          className={classes.checkbox}
          checked={selected}
          onChange={handleCheckboxChange}
        />
      </div>

      <div className={classes.contentContainer}>
        <div>{candidate.name}</div>
        <div className={classes.locationText}>{candidate.location}</div>
      </div>
      <div
        style={{
          display: "flex",
          gap: "12px",
        }}
      >
        <div>
          {candidate.has_pv ? (
            <MdOutlineSolarPower size={28} color="#EC9A3C" />
          ) : (
            <MdOutlineSolarPower color="#5F6B7C" size={32} />
          )}
        </div>

        <div>
          {candidate.has_battery ? (
            <PiBatteryChargingFill color="#B6D94C" size={32} />
          ) : (
            <PiBatteryChargingFill color="#5F6B7C" size={32} />
          )}
        </div>
      </div>
    </div>
  );
}
