import { AnchorButton, H3, Icon } from "@blueprintjs/core";
import { Battery } from "@ec1/types/Battery";
import { PlatformCustomer } from "@ec1/types/PlatformCustomer";
import { PlatformProperty } from "@ec1/types/PlatformProperty";
import { PropertyTariffMapping } from "@ec1/types/PropertyTariffMapping";
import { Tariff } from "@ec1/types/Tariff";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { useFetch } from "src/common/fetcher/effects";
import { PlatformHeaderField } from "src/common/ui/PlatformHeaderField.react";
import BatteryChargingModeTag from "./BatteryChargingModeTag.react";
import BatteryStationStateTag from "./BatteryStationStateTag.react";
import BatteryStatusTag from "./BatteryStatusTag.react";

interface PlatformDeviceDetailViewHeaderProps {
  device: Battery | null;
  isLoading?: boolean;
  setActiveTab: (tabId: string) => void;
}

function getCurrentTariffId(
  propertyId?: number,
  tariffData?: PropertyTariffMapping[]
): number | null {
  if (!propertyId || !tariffData) {
    return null;
  }
  const currentDate = DateTime.now();

  for (const mapping of tariffData) {
    const validFrom = DateTime.fromISO(mapping.valid_from);
    const validTo = DateTime.fromISO(mapping.valid_to);

    if (currentDate >= validFrom && currentDate <= validTo) {
      return mapping.tariff ?? null;
    }
  }

  return null;
}

function formatSolarCapacity(solarSizeInKw: number | undefined): string {
  if (!solarSizeInKw) {
    return "N/A";
  }

  let formattedUnit = "W";
  let formattedCapacity = solarSizeInKw * 1000; // Convert to W

  if (formattedCapacity >= 1000000) {
    formattedCapacity = +(formattedCapacity / 1000000).toFixed(3);
    formattedUnit = "MW";
  } else if (formattedCapacity >= 1000) {
    formattedCapacity = +(formattedCapacity / 1000).toFixed(3);
    formattedUnit = "kW";
  }

  return `${formattedCapacity} ${formattedUnit}`;
}

function formatBatteryCapacity(
  batteryCapacityInWh: number | undefined
): string {
  if (!batteryCapacityInWh) {
    return "N/A";
  }

  let formattedUnit = "Wh";
  let formattedCapacity = batteryCapacityInWh;

  if (formattedCapacity >= 1000000) {
    formattedCapacity = +(formattedCapacity / 1000000).toFixed(2);
    formattedUnit = "MWh";
  } else if (formattedCapacity >= 1000) {
    formattedCapacity = +(formattedCapacity / 1000).toFixed(2);
    formattedUnit = "kWh";
  }

  return `${formattedCapacity} ${formattedUnit}`;
}

export default function PlatformDeviceDetailViewHeader({
  device,
  setActiveTab,
}: PlatformDeviceDetailViewHeaderProps) {
  const [copied, setCopied] = useState(false);

  const handleCopyUUID = async () => {
    if (device?.uuid) {
      try {
        await navigator.clipboard.writeText(device.uuid);
        setCopied(true);
        setTimeout(() => setCopied(false), 500);
      } catch (e) {
        console.error("Failed to copy UUID", e);
      }
    }
  };

  const tariffMappingFetchUrl = useMemo(() => {
    return device?.assigned_property
      ? `/api/tariffs/property-mapping/?property=${device.assigned_property}`
      : null;
  }, [device?.assigned_property]);

  const { data: propertyTariffMappingResponse } = useFetch<{
    results: PropertyTariffMapping[];
  }>(tariffMappingFetchUrl, { useCache: true });

  const tariffId = useMemo(() => {
    return device?.assigned_property && propertyTariffMappingResponse?.results
      ? getCurrentTariffId(
          device.assigned_property,
          propertyTariffMappingResponse.results
        )
      : null;
  }, [device?.assigned_property, propertyTariffMappingResponse?.results]);

  const tariffFetchUrl = useMemo(() => {
    return tariffId ? `/api/tariffs/${tariffId}/` : null;
  }, [tariffId]);

  const { data: tariff } = useFetch<Tariff>(tariffFetchUrl, { useCache: true });

  const selectedTariff = useMemo(() => {
    return tariffFetchUrl ? tariff : null;
  }, [tariffFetchUrl, tariff]);

  const propertyFetchUrl = useMemo(() => {
    return device?.assigned_property
      ? `/api/properties/${device.assigned_property}/`
      : null;
  }, [device?.assigned_property]);

  const { data: property } = useFetch<PlatformProperty>(propertyFetchUrl, {
    useCache: true,
  });

  const selectedProperty = useMemo(() => {
    return propertyFetchUrl ? property : null;
  }, [propertyFetchUrl, property]);

  const customerFetchUrl = useMemo(() => {
    return selectedProperty?.customer
      ? `/api/customers/${selectedProperty.customer}/`
      : null;
  }, [selectedProperty?.customer]);

  const { data: customer } = useFetch<PlatformCustomer>(customerFetchUrl, {
    useCache: true,
  });

  const selectedCustomer = useMemo(() => {
    return customerFetchUrl ? customer : null;
  }, [customerFetchUrl, customer]);

  const handleCapacityClick = () => {
    setActiveTab("device_properties");
    setTimeout(() => {
      const tabsContainer = document.getElementById("device-tabs-container");
      if (tabsContainer) {
        tabsContainer.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  return (
    <div
      style={{
        fontFamily: "Barlow",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "8px",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <div>
          <H3>{device?.brand ?? ""}</H3>
        </div>

        <PlatformHeaderField
          label="UUID"
          value={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <span>{device?.uuid}</span>
              <Icon
                icon="duplicate"
                onClick={handleCopyUUID}
                size={14}
                style={{
                  marginLeft: 5,
                  cursor: "pointer",
                  color: "#ccc",
                }}
                title="Copy"
                onMouseEnter={(e) => {
                  (e.currentTarget as HTMLElement).style.color = "#aaa";
                }}
                onMouseLeave={(e) => {
                  (e.currentTarget as HTMLElement).style.color = "#ccc";
                }}
              />
              {copied && (
                <span
                  style={{
                    color: "#aaa",
                    fontSize: 12,
                    fontFamily: "Barlow",
                    marginLeft: 5,
                  }}
                >
                  Copied!
                </span>
              )}
            </div>
          }
        />

        <div
          style={{
            display: "flex",
            gap: "12px",
            marginTop: 0,
            alignItems: "flex-end",
          }}
        >
          <PlatformHeaderField
            label="Solar Capacity"
            value={formatSolarCapacity(device?.solar_size_in_kw)}
            onClick={handleCapacityClick}
          />
          <PlatformHeaderField
            label="Battery Capacity"
            value={formatBatteryCapacity(device?.battery_capacity_in_wh)}
            onClick={handleCapacityClick}
          />
          {device?.estimated_capacity_in_wh ? (
            <div
              style={{
                fontFamily: "Barlow",
                fontSize: "12px",
              }}
            >
              {`(Estimated Battery Capacity: ${formatBatteryCapacity(
                device?.estimated_capacity_in_wh
              )})`}
            </div>
          ) : null}
        </div>
        <div
          style={{
            display: "flex",
            gap: "12px",
            marginTop: 0,
          }}
        >
          {device && (
            <PlatformHeaderField
              label="Status"
              value={<BatteryStationStateTag battery={device} />}
            />
          )}
        </div>

        <div
          style={{
            display: "flex",
            gap: "12px",
            marginTop: 0,
          }}
        >
          <PlatformHeaderField
            label="Battery level"
            value={`${device?.battery_level ?? 0} %`}
          />
          <PlatformHeaderField
            label="Station name"
            value={`${device?.station_name}`}
          />
        </div>

        <div
          style={{
            display: "flex",
            gap: "12px",
            marginTop: 0,
          }}
        >
          <PlatformHeaderField
            label="Tariff"
            href={selectedTariff ? `/tariffs/${selectedTariff.id}` : undefined}
            value={selectedTariff ? selectedTariff.display_name : "-"}
          />
        </div>

        <div
          style={{
            display: "flex",
            gap: "12px",
            marginTop: 0,
          }}
        >
          <PlatformHeaderField
            label="Charging state"
            value={device && <BatteryStatusTag battery={device} />}
          />
          <PlatformHeaderField
            label="Charging mode"
            value={device && <BatteryChargingModeTag battery={device} />}
          />
        </div>

        <div
          style={{
            display: "flex",
            gap: "12px",
            marginTop: 0,
          }}
        >
          <PlatformHeaderField
            label="Property"
            href={
              selectedProperty
                ? `/properties/${selectedProperty.id}`
                : undefined
            }
            value={selectedProperty ? selectedProperty.property_uid : "-"}
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: "12px",
            marginTop: 0,
          }}
        >
          <PlatformHeaderField
            label="Customer"
            href={
              selectedCustomer ? `/customers/${selectedCustomer.id}` : undefined
            }
            value={
              selectedCustomer
                ? `${selectedCustomer.first_name} ${selectedCustomer.last_name}`
                : "-"
            }
          />
        </div>
        {device?.synced_at && (
          <div
            style={{
              fontFamily: "Barlow",
              fontSize: "12px",
              marginTop: "8px",
            }}
          >
            Last sync: {DateTime.fromISO(device?.synced_at).toRelative()}
          </div>
        )}
      </div>

      {device?.portal_url && (
        <div style={{ marginTop: "16px" }}>
          <AnchorButton
            outlined
            icon="third-party"
            href={device?.portal_url}
            text="Vendor Portal"
            target="_blank"
          />
        </div>
      )}
    </div>
  );
}
