import {
  Button,
  ButtonGroup,
  Dialog,
  H3,
  H4,
  Intent,
  Spinner,
} from "@blueprintjs/core";
import { Edit, Trash } from "@blueprintjs/icons";
import { PropertyTariffMapping } from "@ec1/types/PropertyTariffMapping";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate, useParams } from "react-router-dom";
import { Ec1Toaster } from "../../../ui/components/Ec1Toaster.react";
import countries from "../../../ui/geoData/countries.json";
import SpinningLogo from "../../../ui/logo/SpinningLogo.react";
import PlatformCustomerAssignWidget from "../customers/PlatformCustomerAssignWidget.react";
import PlatformBatteryMetricsDevicesConsumptionChart from "../devices/PlatformBatteryMetricsDevicesConsumptionChart.react";
import PlatformDevicesWidget from "../devices/PlatformDevicesWidget.react";
import PlatformPropertyDialog from "./PlatformPropertyDialog.react";
import PlatformPropertyPricesChart from "./PlatformPropertyPricesChart.react";
import PlatformPropertyTariffWidget from "./PlatformPropertyTariffWidget.react";
import PlatformSitePropertyMap from "./PlatformSitePropertyMap.react";
import { useProperty, usePropertyTariffMappings } from "./properties";

const useStyles = createUseStyles({
  button: {
    width: 50,
    height: 42,
    borderRadius: 0,
    textAlign: "center",
    borderRightWidth: "0 !important",
    position: "relative",
    "&:active": {
      outline: "none",
      outlineOffset: 0,
    },
    "&:focus": {
      outline: "none",
      outlineOffset: 0,
    },
  },
  buttonFirst: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  buttonLast: {
    borderWidth: "1px !important",
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  buttonLabel: {
    fontFamily: "Barlow",
    color: "#c5cbd3",
    fontSize: 10,
  },
  disabled: {
    opacity: 0.5,
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  spinner: {
    width: 20,
    height: 20,
  },
  dialog: {
    fontFamily: "Barlow",
    width: 360,
    boxShadow: "inset 0 0 0 1px rgba(255, 255, 255, 0.2) !important",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    margin: 10,
    marginBottom: 15,
  },
  dialogBody: {
    fontFamily: "Barlow",
    padding: 20,
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 14,
    color: "white",
  },
  warningBody: {
    fontFamily: "Barlow",
    padding: 20,
    paddingTop: 10,
    fontSize: 14,
    color: "#c39900",
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(440px, 1fr))",
    gap: "16px",
    marginTop: "24px",
  },
});

function showToast(
  message: string,
  intent: Intent,
  buttonText?: string,
  buttonOnClick?: () => void
) {
  Ec1Toaster.show({
    timeout: 5000,
    message: (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: 18,
            fontFamily: "Barlow",
          }}
        >
          <div style={{ marginRight: 15, height: 20 }}>{message}</div>
          {buttonText && (
            <Button
              intent="primary"
              text={buttonText}
              onClick={buttonOnClick}
              style={{
                height: 20,
                padding: "0 5px",
                minHeight: "unset",
                minWidth: "unset",
                fontSize: 12,
              }}
            />
          )}
        </div>
      </>
    ),
    intent: intent,
  });
}

const getCountryName = (countryCode: string): string => {
  const country = countries.find(
    (c) => c.alpha2.toLowerCase() === countryCode.toLowerCase()
  );
  return country ? country.name : countryCode;
};

// Calculate the timezone offset with GMT prefix
const getTimezoneOffset = (timezoneCode: string): string => {
  const offsetHours =
    DateTime.now().setZone(timezoneCode || "system").offset / 60;
  const gmtOffset =
    offsetHours === 0
      ? "GMT"
      : `GMT${offsetHours > 0 ? "+" : ""}${Math.abs(offsetHours)}`;
  return gmtOffset;
};

export default function PlatformSitePropertyDetailViewPage() {
  const classes = useStyles();

  const { propertyId } = useParams<{ propertyId: string }>();
  const parsedPropertyId = parseInt(propertyId ?? "");

  const [customerId, setCustomerId] = useState<number | null>(null);

  const {
    property,
    address,
    fetchProperty,
    deleteProperty,
    deletePropertyTariff,
  } = useProperty(parsedPropertyId);

  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [dialogKey, setDialogKey] = useState(0);
  const [customerWidgetKey, setCustomerWidgetKey] = useState(0);

  const [selectedTariffMapping, setSelectedTariffMapping] =
    useState<PropertyTariffMapping | null>(null);
  const [isDeletingTariffMapping, setIsDeletingTariffMapping] = useState(false);
  const [showDeleteTariffMappingConfirm, setShowDeleteTariffMappingConfirm] =
    useState(false);

  const navigate = useNavigate();

  const { propertyTariffMappings, fetchPropertyTariffs } =
    usePropertyTariffMappings(parsedPropertyId);

  const handleEditClick = () => {
    setShowEditDialog(true);
    setDialogKey((prevKey) => prevKey + 1);
  };

  const handleDeleteClick = () => {
    setShowDeleteConfirm(true);
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    setShowDeleteConfirm(false);

    const response = await deleteProperty(parsedPropertyId);

    if (response.success) {
      showToast("Property deleted successfully.", Intent.SUCCESS);
      navigate("/properties/");
    } else {
      showToast(response.error, Intent.DANGER);
    }

    setIsDeleting(false);
  };

  const handleCloseDeleteDialog = () => {
    setShowDeleteConfirm(false);
  };

  const renderDeleteConfirmDialog = () => (
    <Dialog
      className={`bp5-dark ${classes.dialog}`}
      isOpen={showDeleteConfirm}
      onClose={handleCloseDeleteDialog}
      title="Confirm Deletion"
      icon="trash"
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
    >
      <div style={{ marginTop: "8px" }}>
        <div className={classes.dialogBody}>
          Are you sure you want to delete this property?
        </div>
        <div className={classes.warningBody}>
          <b>Warning</b>: If this property is part of an integration it might be
          recreated on the next system sync.
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          outlined
          onClick={handleCloseDeleteDialog}
          disabled={isDeleting}
        >
          Cancel
        </Button>
        <div style={{ width: "8px" }} />
        <Button
          outlined
          intent={Intent.DANGER}
          onClick={handleConfirmDelete}
          disabled={isDeleting}
        >
          Delete
        </Button>
      </div>
    </Dialog>
  );

  const handleConfirmDeleteTariffMapping = async () => {
    if (selectedTariffMapping) {
      setIsDeletingTariffMapping(true);
      setShowDeleteTariffMappingConfirm(false);

      const response = await deletePropertyTariff(selectedTariffMapping);

      if (response.success) {
        await fetchPropertyTariffs(); // Refresh the list
        showToast("Tariff deleted successfully.", Intent.SUCCESS);
      } else {
        showToast(response.error, Intent.DANGER);
      }

      setSelectedTariffMapping(null); // Reset selection
      setIsDeletingTariffMapping(false);
    }
  };

  const handleCloseDeleteTariffMappingDialog = () => {
    setShowDeleteTariffMappingConfirm(false);
  };

  const renderDeleteTariffMappingConfirmDialog = () => (
    <Dialog
      className={`bp5-dark ${classes.dialog}`}
      isOpen={showDeleteTariffMappingConfirm}
      onClose={handleCloseDeleteTariffMappingDialog}
      title="Confirm Tariff Deletion"
      icon="trash"
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
    >
      <div style={{ marginTop: "8px" }}>
        <div className={classes.dialogBody}>
          Are you sure you want to delete this tariff?
        </div>
        <div className={classes.warningBody}>
          <b>Warning</b>: If this property is part of an integration it might be
          recreated on the next system sync.
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          outlined
          onClick={handleCloseDeleteTariffMappingDialog}
          disabled={isDeleting}
        >
          Cancel
        </Button>
        <div style={{ width: "8px" }} />
        <Button
          outlined
          intent={Intent.DANGER}
          onClick={handleConfirmDeleteTariffMapping}
          disabled={isDeleting}
        >
          Delete
        </Button>
      </div>
    </Dialog>
  );

  // Auto-refreshing property data every minute
  useEffect(() => {
    const interval = setInterval(() => {
      fetchProperty(parsedPropertyId);
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, [parsedPropertyId, fetchProperty]);

  useEffect(() => {
    if (property?.customer) {
      setCustomerId(property.customer);
    }
  }, [property]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
        padding: "24px",
      }}
    >
      {renderDeleteConfirmDialog()}
      {renderDeleteTariffMappingConfirmDialog()}
      {property !== null ? (
        <div style={{ flex: 1, fontFamily: "Barlow" }}>
          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <div
              style={{
                borderRadius: 6,
                padding: "24px",
                paddingBottom: 18,
                fontFamily: "Barlow",
                width: "100%",
                backgroundColor: "#252A31",
                fontFeatureSettings: "Barlow",
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(500px, 1fr))",
                gap: "24px",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{ width: "100%", marginLeft: 5, fontFamily: "Barlow" }}
                >
                  <div
                    style={{
                      fontFamily: "Barlow",
                      letterSpacing: 1,
                      fontWeight: 700,
                      fontSize: 24,
                      color: "rgb(211, 216, 222)",
                      marginBottom: 16,
                      flex: 1,
                    }}
                  >
                    <H3>{property?.property_uid}</H3>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ marginBottom: "0.75em" }}>
                      <strong>Address:</strong>{" "}
                      {address?.freeform_address ?? ""}
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ marginBottom: "0.75em" }}>
                      <strong>Country:</strong>{" "}
                      {address?.country_code
                        ? getCountryName(address.country_code)
                        : ""}
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ marginBottom: "0.75em" }}>
                      <strong>Local timezone:</strong>{" "}
                      <span style={{ fontFamily: "Barlow" }}>
                        <span style={{ paddingRight: 5 }}>
                          {property.local_timezone}
                        </span>
                        <span
                          style={{
                            color: "rgba(255, 255, 255, 0.5)",
                            fontSize: "0.8em",
                            fontFamily: "Barlow",
                          }}
                        >
                          {property.local_timezone &&
                            `(${getTimezoneOffset(property.local_timezone)})`}
                        </span>
                      </span>
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: "8px",
                      borderColor: "#5F6B7C",
                      borderWidth: "1px",
                      borderStyle: "dashed",
                      padding: "12px",
                      borderRadius: "8px",
                    }}
                  >
                    {property?.id && (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ marginBottom: "6px" }}>
                          <strong>Customer</strong>
                        </div>

                        <PlatformCustomerAssignWidget
                          key={customerWidgetKey}
                          propertyId={property.id}
                          customerId={customerId}
                          onCustomerSelect={setCustomerId}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div>
                  <ButtonGroup>
                    <Button
                      minimal
                      outlined
                      disabled={isDeleting}
                      className={`${classes.button} ${classes.buttonFirst}`}
                      onClick={handleEditClick}
                    >
                      <div className={classes.iconContainer}>
                        {isEditing ? (
                          <Spinner className={classes.spinner} size={14} />
                        ) : (
                          <Edit
                            size={12}
                            style={{ margin: 5, color: "white" }}
                          />
                        )}
                      </div>
                      <div className={classes.buttonLabel}>Edit</div>
                    </Button>
                    <Button
                      minimal
                      outlined
                      disabled={isDeleting}
                      className={`${classes.button} ${classes.buttonLast}`}
                      onClick={handleDeleteClick}
                    >
                      <div className={classes.iconContainer}>
                        {isDeleting ? (
                          <Spinner className={classes.spinner} size={14} />
                        ) : (
                          <Trash
                            size={12}
                            style={{ margin: 5, color: "white" }}
                          />
                        )}
                      </div>
                      <div
                        className={`${classes.buttonLabel} ${
                          isDeleting ? classes.disabled : ""
                        }`}
                      >
                        Delete
                      </div>
                    </Button>
                  </ButtonGroup>
                </div>
              </div>

              <div
                style={{
                  display: "grid",
                  gridTemplateRows: "auto 1fr",
                  height: "100%",
                  marginLeft: 5,
                }}
              >
                {address?.longitude && address?.latitude && (
                  <div>
                    <div
                      style={{
                        fontFamily: "Barlow",
                        letterSpacing: 1,
                        fontWeight: 700,
                        fontSize: 24,
                        color: "rgb(211, 216, 222)",
                      }}
                    >
                      <H4>Location</H4>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        height: 250,
                        maxWidth: 500,
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          width: "100%",
                          borderRadius: "3px",
                          border: "solid 1px rgba(255, 255, 255, 0.1)",
                        }}
                      >
                        <PlatformSitePropertyMap
                          height={"240px"}
                          longitude={address?.longitude}
                          latitude={address?.latitude}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div>
            <div className={classes.gridContainer}>
              <PlatformDevicesWidget
                propertyId={parsedPropertyId}
                height={360}
              />
              <PlatformBatteryMetricsDevicesConsumptionChart
                propertyId={parsedPropertyId}
                height={360}
              />
              <PlatformPropertyTariffWidget
                height={360}
                propertyId={parsedPropertyId}
                propertyTariffMappings={propertyTariffMappings}
                isLoading={isDeletingTariffMapping}
                selectedTariffMapping={selectedTariffMapping}
                setShowDeleteTariffMappingConfirm={
                  setShowDeleteTariffMappingConfirm
                }
                setSelectedTariffMapping={setSelectedTariffMapping}
                onEnrollmentSuccess={fetchPropertyTariffs}
              />
              <PlatformPropertyPricesChart
                propertyId={parsedPropertyId}
                height={360}
              />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: "340px",
              marginTop: "12px",
            }}
          >
            <div
              style={{ width: "33.3%", height: "100%", minWidth: 500 }}
            ></div>
          </div>
        </div>
      ) : (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            margin: "auto 0",
          }}
        >
          <SpinningLogo isLoadingSpinner={true} height="50px" />
        </div>
      )}
      {showEditDialog && (
        <PlatformPropertyDialog
          key={dialogKey}
          isOpen={showEditDialog}
          isEdit={true}
          propertyData={property}
          address={address}
          onClose={() => setShowEditDialog(false)}
          onEditSuccess={() => {
            setShowEditDialog(false);
            fetchProperty(parsedPropertyId);
            setCustomerWidgetKey((prevKey) => prevKey + 1);
          }}
          setIsLoadingParent={setIsEditing}
          customerId={customerId}
          onCustomerSelect={setCustomerId}
        />
      )}
    </div>
  );
}
