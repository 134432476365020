import { Button, ButtonGroup, MenuItem, Spinner } from "@blueprintjs/core";
import { ItemRenderer, Select } from "@blueprintjs/select";
import { DateTime } from "luxon";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createUseStyles } from "react-jss";
import ReactFlow, {
  Background,
  BackgroundVariant,
  Controls,
  MiniMap,
  Node,
  useReactFlow,
  useStore,
} from "reactflow";
import "reactflow/dist/style.css";
import { useCustomers } from "../../customers/customers";
import { useDevices } from "../../devices/devices";
import { useProperties } from "../../properties/properties";
import GridEdge from "./GridEdge.react";
import { getGridGraph, GraphSorter } from "./GridGraph";
import GridNode, { ZOOM_THRESHOLD } from "./GridNode.react";

const nodeTypes = { gridNode: GridNode };
const edgeTypes = { gridEdge: GridEdge };

const useStyles = createUseStyles({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(17, 17, 17)",
    color: "rgb(243, 244, 246)",
    overflow: "hidden",
    position: "relative",
  },
  lastUpdate: {
    position: "absolute",
    top: 0,
    fontFamily: "Barlow",
    color: "#ABB3BF",
    fontSize: "14px",
    padding: "20px",
  },
  controls: {
    "& .react-flow__controls-button": {
      fill: "#ccc",
      background: "#292f37",
      color: "white !important",
      borderBottom: "1px solid #383E47",
      "&:hover": {
        background: "#414852",
      },
      "&:last-child": {
        borderBottom: "none",
      },
    },
  },
  minimap: {
    height: 150,
    border: "none",
    background: "#262a30",
    "& .react-flow__controls-button": {
      fill: "#ccc",
      background: "#292f37",
      color: "white !important",
      borderBottom: "1px solid #383E47",
      "&:hover": {
        background: "#414852",
      },
      "&:last-child": {
        borderBottom: "none",
      },
    },
  },
  spinnerContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#1C2127",
  },
  emptyContainer: {
    backgroundColor: "#1C2127",
    width: "100%",
    height: "100%",
  },
  sortContainer: {
    position: "absolute",
    top: 15,
    right: 15,
  },
  menuItem: {
    outline: "none",
  },
  sorterLabel: {
    fontFamily: "Barlow",
    flexGrow: "unset !important",
    flexShrink: "unset !important",
  },
  button: {
    width: 190,
    outline: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  label: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontFamily: "Barlow",
    paddingLeft: 2,
  },
  labelNoSelect: {
    extend: "label",
    opacity: 0.5,
  },
});

const SORTERS: GraphSorter[] = [
  {
    id: "station_state_asc",
    field: "station_state",
    name: "Status",
  },
  {
    id: "is_smart_charging_asc",
    field: "is_smart_charging",
    name: "Charging Mode",
  },
  {
    id: "grid_state_asc",
    field: "grid_state",
    name: "Grid Power",
  },
  // Add more sorter fields as needed
];

const PlatformSiteDeviceGridView = React.memo(
  function PlatformSiteDeviceGridView() {
    const { devices, fetchAllDevices } = useDevices();
    const { properties, fetchAllProperties } = useProperties();
    const { customers, fetchAllCustomers } = useCustomers();

    const [isFirstLoad, setIsFirstLoad] = useState(true);

    const [state, setState] = useState({
      isLoading: true,
      lastUpdateTime: null as Date | null,
    });

    const [sorter, setSorter] = useState<GraphSorter | null>(null);

    const zoomLevel = useStore((store) => store.transform[2]);
    const isZoomed = zoomLevel >= ZOOM_THRESHOLD;

    const classes = useStyles();

    const reactFlowRef = useRef<any>(null);

    useEffect(() => {
      fetchAllDevices();
      fetchAllProperties();
      fetchAllCustomers();

      const intervalId = setInterval(() => {
        fetchAllDevices();
        fetchAllProperties();
        fetchAllCustomers();
      }, 60000);

      return () => clearInterval(intervalId);
    }, [fetchAllCustomers, fetchAllDevices, fetchAllProperties]);

    useEffect(() => {
      if (devices !== null && customers !== null && properties !== null) {
        setState({
          isLoading: false,
          lastUpdateTime: new Date(),
        });
      }
    }, [customers, devices, properties]);

    const graph = useMemo(() => {
      return getGridGraph(
        devices ?? [],
        properties,
        customers,
        isZoomed,
        sorter
      );
    }, [devices, properties, customers, isZoomed, sorter]);

    const getNodeStrokeColor = useCallback(
      (node: Node) => {
        if (node.data.isRoot) {
          return node.data.color;
        }
        return (node.data.isChild && !isZoomed) ||
          (!node.data.isChild && isZoomed)
          ? "transparent"
          : node.data.color;
      },
      [isZoomed]
    );

    const getNodeColor = useCallback(
      (node: Node) => {
        if (node.data.isRoot) {
          return node.data.color + "22";
        }
        return (node.data.isChild && !isZoomed) ||
          (!node.data.isChild && isZoomed)
          ? "transparent"
          : node.data.color + "22";
      },
      [isZoomed]
    );

    const lastUpdateTimeString = useMemo(
      () =>
        state.lastUpdateTime
          ? DateTime.fromJSDate(state.lastUpdateTime).toLocaleString(
              DateTime.DATETIME_MED
            )
          : "N/A",
      [state.lastUpdateTime]
    );

    const { fitView } = useReactFlow();

    // Call fitView strictly after full loading of nodes
    useEffect(() => {
      if (isFirstLoad && !state.isLoading) {
        const timer = setTimeout(() => {
          fitView({ padding: 0.1, includeHiddenNodes: true });
          setIsFirstLoad(false);
        }, 1);

        return () => clearTimeout(timer);
      }
    }, [state.isLoading, fitView, isFirstLoad]);

    if (state.isLoading) {
      return (
        <div className={classes.spinnerContainer}>
          <Spinner size={60} />
        </div>
      );
    }

    if (devices === null) {
      return <div className={classes.emptyContainer} />;
    }

    const renderSorter: ItemRenderer<GraphSorter> = (
      sorter: GraphSorter,
      { handleClick, handleFocus }
    ) => {
      return (
        <MenuItem
          key={sorter.id}
          onClick={handleClick}
          onFocus={handleFocus}
          className={classes.menuItem}
          text={sorter.name}
          textClassName={classes.sorterLabel}
        />
      );
    };

    return (
      <div className={classes.container}>
        <div className={classes.lastUpdate}>
          {`Last updated at ${lastUpdateTimeString}`}
        </div>
        <div className={classes.sortContainer}>
          <ButtonGroup fill>
            <Select
              items={SORTERS}
              itemRenderer={renderSorter}
              onItemSelect={setSorter}
              filterable={false}
              popoverProps={{ matchTargetWidth: true }}
              menuProps={{
                style: { padding: 0, maxHeight: 500, overflow: "auto" },
              }}
            >
              <Button
                icon="changes"
                rightIcon="caret-down"
                alignText="left"
                className={classes.button}
                disabled={state.isLoading}
              >
                <span
                  className={sorter ? classes.label : classes.labelNoSelect}
                >
                  {sorter?.name ?? "Sort by..."}
                </span>
              </Button>
            </Select>
            <Button
              icon="cross"
              disabled={state.isLoading || !sorter}
              onClick={() => {
                setSorter(null);
              }}
            />
          </ButtonGroup>
        </div>
        <div style={{ height: "100%" }}>
          <ReactFlow
            ref={reactFlowRef}
            minZoom={0.001}
            maxZoom={2}
            nodes={graph.nodes}
            edges={graph.edges}
            nodeTypes={nodeTypes}
            proOptions={{ hideAttribution: true }}
            edgeTypes={edgeTypes}
            fitView={true}
            fitViewOptions={{ padding: 0.1 }}
            elementsSelectable={false}
            draggable={false}
            nodesFocusable={false} // prevent node 'halo' effect by BlueprintJS global css
            edgesFocusable={false} // prevent edge 'halo' effect by BlueprintJS global css
          >
            <Background
              variant={BackgroundVariant.Dots}
              size={1}
              color={"#383E47"}
            />
            <Controls className={classes.controls} showInteractive={false} />
            <MiniMap
              pannable
              zoomable
              className={classes.minimap}
              nodeStrokeWidth={5}
              nodeStrokeColor={getNodeStrokeColor}
              nodeColor={getNodeColor}
              nodeBorderRadius={100}
              maskColor="rgba(24, 28, 33, 0.8)"
            />
          </ReactFlow>
        </div>
      </div>
    );
  }
);

export default PlatformSiteDeviceGridView;
