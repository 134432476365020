import { Spinner } from "@blueprintjs/core";
import { Price } from "@ec1/types/Price";
import HighchartsReact from "highcharts-react-official";
import Highcharts, {
  TooltipFormatterContextObject,
} from "highcharts/highstock";
import { DateTime } from "luxon";
import { useEffect, useMemo, useRef, useState } from "react";
import { useFetch } from "src/common/fetcher/effects";
import { useFetchAll } from "src/common/fetcher/useFetchAll";
import { ChartStateV2, getChartGranularityV2 } from "src/ui/utils/chartUtils";
import { getCurrencySymbol } from "../properties/tariffs";

interface PlatformBatteryPriceChartChartProps {
  propertyId: number;
  height?: number;
  chartState: ChartStateV2;
  setChartState: React.Dispatch<React.SetStateAction<ChartStateV2>>;
  timezone?: string;
  timezoneLabel?: string;
}

export default function PlatformBatteryPriceChartChart({
  propertyId,
  height = 320,
  chartState,
  setChartState,
  timezone,
  timezoneLabel,
}: PlatformBatteryPriceChartChartProps) {
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [currency, setCurrency] = useState("");

  const fetchUrlPrices = useMemo(() => {
    if (
      chartState.start &&
      chartState.end &&
      chartState.start <= DateTime.now().setZone(timezone || "system")
    ) {
      return `/api/prices/?related_property=${propertyId}&start_time=${chartState.start
        .toUTC()
        .toISO({ includeOffset: false })}&end_time=${chartState.end
        .toUTC()
        .toISO({
          includeOffset: false,
        })}&ordering=utc_time`;
    }
    return null;
  }, [propertyId, chartState, timezone]);

  const fetchUrlForecasts = null;

  /* Uncomment the below to enable forecasts */
  // const fetchUrlForecasts = useMemo(() => {
  //   if (
  //     chartState.start &&
  //     chartState.end &&
  //     (!lastPriceTimestamp ||
  //       (lastPriceTimestamp &&
  //         chartState.end > DateTime.fromISO(lastPriceTimestamp)))
  //   ) {
  //     return `/api/devices/${
  //       chartState.granularity
  //     }/battery-forecasts/?battery=${batteryId}&utc_time__gte=${chartState.start
  //       .toUTC()
  //       .toISO({ includeOffset: false })}&utc_time__lte=${chartState.end
  //       .toUTC()
  //       .toISO({ includeOffset: false })}&ordering=utc_time`;
  //   }
  //   return null;
  // }, [batteryId, chartState, lastPriceTimestamp]);

  const { data: priceData, isLoading: isLoadingPrices } =
    useFetchAll<Price>(fetchUrlPrices);

  const { data: forecastData, isLoading: isLoadingForecasts } = useFetch<{
    results: Price[];
  }>(fetchUrlForecasts);

  const dataLoaded = useMemo(() => {
    return (
      (!fetchUrlPrices || (fetchUrlPrices && priceData)) &&
      (!fetchUrlForecasts || (fetchUrlForecasts && forecastData))
    );
  }, [fetchUrlForecasts, fetchUrlPrices, forecastData, priceData]);

  const noDataAvailable = useMemo(() => {
    return fetchUrlPrices === null && fetchUrlForecasts === null;
  }, [fetchUrlForecasts, fetchUrlPrices]);

  useEffect(() => {
    if (priceData?.length) {
      const firstCurrency = priceData.filter((x: Price) => x.currency);
      if (firstCurrency.length) {
        setCurrency(getCurrencySymbol(firstCurrency[0].currency));
      }
    }
  }, [priceData]);

  useEffect(() => {
    if (noDataAvailable) {
      setIsLoading(false);
    } else if (dataLoaded && chartRef.current?.chart) {
      const chart = chartRef.current.chart;

      const importPrices = priceData?.filter(
        (p) => p.is_export_price === false
      );
      const exportPrices = priceData?.filter((p) => p.is_export_price === true);

      if (importPrices?.length) {
        chart.series[0].setData(
          importPrices.map((item: Price) => [
            DateTime.fromISO(item.utc_time, { zone: "utc" }).toMillis(),
            item.price_per_kwh,
          ]),
          false
        );
      }

      if (exportPrices?.length) {
        chart.series[1].setData(
          exportPrices.map((item: Price) => [
            DateTime.fromISO(item.utc_time, { zone: "utc" }).toMillis(),
            item.price_per_kwh,
          ]),
          false
        );
      }

      // Find the minimum and maximum timestamps
      const importTimestamps = importPrices?.map((p) =>
        DateTime.fromISO(p.utc_time, { zone: "utc" }).toMillis()
      );
      const exportTimestamps = exportPrices?.map((p) =>
        DateTime.fromISO(p.utc_time, { zone: "utc" }).toMillis()
      );

      const minTimestamp = Math.min(
        ...(importTimestamps || []),
        ...(exportTimestamps || [])
      );
      const maxTimestamp = Math.max(
        ...(importTimestamps || []),
        ...(exportTimestamps || [])
      );

      const nowMillis = DateTime.now()
        .setZone(timezone || "system")
        .toMillis();

      // If chartState.end is in the future, keep it as the end extreme
      let maxX;
      if (chartState.end) {
        const chartEndMillis = chartState.end.toMillis();
        maxX = chartEndMillis > nowMillis ? chartEndMillis : maxTimestamp;
      } else {
        maxX = maxTimestamp;
      }

      // Set the extremes based on data points if available; fallback to chartState
      if (minTimestamp !== Infinity && maxX !== -Infinity) {
        chart.xAxis[0].setExtremes(minTimestamp, maxX);
      } else if (chartState.start && chartState.end) {
        chart.xAxis[0].setExtremes(
          chartState.start.toMillis(),
          chartState.end.toMillis()
        );
      }

      // Redraw the chart
      chart.redraw();

      setIsLoading(false);
    }
  }, [
    chartState,
    dataLoaded,
    forecastData,
    isLoading,
    isLoadingForecasts,
    isLoadingPrices,
    priceData,
    noDataAvailable,
    timezone,
  ]);

  const chartOptions = useMemo(() => {
    const localNow = DateTime.now().setZone(timezone || "system");
    const localDate = localNow.toMillis();

    return {
      chart: {
        height: height - 65,
        marginRight: 20,
        marginLeft: 64,
        marginTop: 32,
      },
      time: {
        timezone,
        useUTC: timezone !== undefined,
      },
      rangeSelector: {
        enabled: false,
      },
      xAxis: {
        minRange: 60 * 1000,
        crosshair: true,
        title: {
          y: 5,
          text: `Time<br><span style="font-size: 11px; color: #999;">${timezoneLabel}</span>`,
        },
        plotLines: [
          {
            color: "#544fc5",
            width: 2,
            value: localDate,
          },
        ],
        events: {
          afterSetExtremes: function (e: any) {
            const chart = e.target.chart;

            if (!chart || !e.min || !e.max || e.trigger !== "zoom") {
              return;
            }

            const minDateTime = DateTime.fromMillis(Math.round(e.min))
              .setZone(timezone)
              .startOf("minute");
            const maxDateTime = DateTime.fromMillis(Math.round(e.max))
              .setZone(timezone)
              .startOf("minute");

            const granularity = getChartGranularityV2(minDateTime, maxDateTime);

            setChartState({
              start: minDateTime,
              end: maxDateTime,
              granularity: granularity,
            });
          },
        },
      },
      tooltip: {
        shared: true,
        useHTML: true,
        formatter: function (this: TooltipFormatterContextObject): string {
          const points = this.points;

          if (
            !points ||
            points.length === 0 ||
            typeof points[0].x !== "number"
          ) {
            return "";
          }

          const pointDate = DateTime.fromMillis(points[0].x).setZone(
            timezone || "system"
          );

          const dateStr = pointDate.toFormat(
            `cccc, dd LLLL ${pointDate.toFormat("HH:mm")}`
          );

          let tooltipContent = `<div style="font-size: 12px; margin-bottom: 5px;">${dateStr}</div>`;

          points.forEach((point) => {
            if (point.series.visible && typeof point.y === "number") {
              const seriesName = point.series.name;
              const sign = point.y < 0 ? "-" : "";
              const valueStr = `${sign}${currency ?? ""}${point.y.toFixed(2)}`;
              const bulletPoint = `<span style="color:${point.color};">\u25CF</span>`;
              tooltipContent += `<div>${bulletPoint} ${seriesName} Price/kWh: <b>${valueStr}</b></div>`;
            }
          });

          return tooltipContent;
        },
        style: {
          fontFamily: "Barlow, sans-serif",
        },
      },
      yAxis: [
        {
          title: {
            text: null,
          },
          labels: {
            y: 4,
            style: {
              fontFamily: "Barlow",
              color: "white",
            },
            formatter: function (
              this: Highcharts.AxisLabelsFormatterContextObject
            ): string {
              const value =
                typeof this.value === "number"
                  ? this.value.toFixed(2)
                  : this.value;
              return (
                (typeof this.value === "number" && parseFloat(value) < 0
                  ? "-"
                  : "") +
                (currency ?? "") +
                value
              );
            },
          },
        },
      ],
      series: [
        {
          type: "line",
          name: "Import",
          color: "rgb(219, 44, 111)",
          dataGrouping: {
            enabled: false,
          },
          showInLegend: true,
          zoneAxis: "x",
          zones: [
            {
              value: localDate,
              color: "rgb(219, 44, 111)",
            },
            {
              color: "rgb(219, 44, 111, 0.5)",
              dashStyle: "ShortDash",
            },
          ],
        },
        {
          type: "line",
          name: "Export",
          color: "rgb(94, 203, 247)",
          dataGrouping: {
            enabled: false,
          },
          showInLegend: true,
          zoneAxis: "x",
          zones: [
            {
              value: localDate,
              color: "rgb(94, 203, 247)",
            },
            {
              color: "rgb(94, 203, 247, 0.5)",
              dashStyle: "ShortDash",
            },
          ],
        },
      ],
      legend: {
        enabled: true,
        align: "right",
        verticalAlign: "top",
        x: -10,
        y: -10,
        padding: 0,
        symbolWidth: 14,
        itemStyle: {
          color: "white",
          fontFamily: "Barlow",
        },
        itemHoverStyle: {
          color: "#ccc",
          fontFamily: "Barlow",
        },
      },
      loading: {
        labelStyle: {
          color: "transparent",
        },
        style: { backgroundColor: "transparent" },
      },
    };
  }, [timezone, height, timezoneLabel, setChartState, currency]);

  return (
    <div
      style={{
        height: height,
        backgroundColor: "rgb(37, 42, 49)",
        borderRadius: 4,
        padding: 20,
        color: "white",
      }}
    >
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              fontSize: 21,
              marginBottom: 10,
            }}
          >
            <b>Prices</b>
          </div>
        </div>
      </div>
      <div>
        {isLoading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: height - 100,
            }}
          >
            <Spinner />
          </div>
        )}
        {noDataAvailable ? (
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 260,
              width: "100%",
            }}
          >
            No data available
          </div>
        ) : (
          <div
            style={{
              visibility: isLoading ? "hidden" : "visible",
            }}
          >
            <HighchartsReact
              highcharts={Highcharts}
              constructorType={"stockChart"}
              options={chartOptions}
              ref={chartRef}
            />
            {(isLoadingPrices || isLoadingForecasts) && (
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  justifyContent: "center",
                  height: height - 70,
                  marginTop: 45 - height,
                }}
              >
                <Spinner />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
